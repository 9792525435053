body {
    // .Calendar__monthText{
    //     pointer-events: none;
    //     cursor: default;
    // }
    .ant-steps-horizontal {
        &:not(.ant-steps-label-vertical) {
            .ant-steps-item {
                padding-left: 0;
            }
        }
    }

    .ant-steps-item {
        padding-top: 12px;

        &:last-child {
            flex: 1 1;
        }

        &:before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            height: 4px;
            background-color: $gray-E9EAEE;
        }

        &.ant-steps-item-active,
        &.ant-steps-item-finish {
            font-weight: 500;

            &:before {
                background-color: $success;
            }
        }
    }

    .ant-steps-item-finish {
        .ant-steps-item-icon {
            border: 2px solid $success;
            display: flex;
            align-items: center;
            justify-content: center;

            > .ant-steps-icon {
                color: $success;
            }

            .ant-steps-finish-icon {
                &:before {
                    content: "\31";
                    font-family: "moneta";
                    display: block !important;
                }

                svg {
                    display: none;
                }
            }
        }
    }

    .ant-steps-horizontal {
        margin-bottom: 24px;
    }

    .ant-steps-item-icon {
        border: none;
    }

    .ant-steps-item-wait {
        .ant-steps-item-icon {
            background-color: $gray-E9EAEE;

            > .ant-steps-icon {
                color: $gray-800;
            }
        }

        > .ant-steps-item-container {
            > .ant-steps-item-content {
                > .ant-steps-item-title {
                    color: $gray-800;
                }
            }
        }
    }

    .ant-steps-item-title {
        color: $gray-800;

        &:after {
            display: none;
        }
    }

    .ant-steps-item-process {
        > .ant-steps-item-container {
            > .ant-steps-item-icon {
                background-color: $success;
            }
        }
    }

    .ant-steps-item-container {
        display: flex;
        align-items: center;
    }
}

.attend-block {
    border-radius: 6px;
    border: 1px solid $gray-300;
    padding: 16px;
    margin-bottom: 28px;
    min-height: 260px;
}

.ant-checkbox-group {
    vertical-align: middle;

    .ant-checkbox-wrapper {
        float: none;
        margin-left: 0 !important;
        display: flex;
        align-items: center;

        .ant-checkbox {
            top: 0;
        }
    }
}

.attend-list {
    + .attend-list {
        margin-top: 8px;
    }

    .user-photo {
        //color: $gray-800;
        font-size: 14px;
        line-height: 24px;
        background-color: #E3E5E8;
        margin-right: 12px;
    }
}

.attend-group-detail {
    .attend-list {
        + .attend-list {
            margin-top: 16px;
        }
    }
}

.att-selected {
    color: $gray-500;
    font-size: 14px;
    line-height: 20px;
}

.datetime-step {
    border-top: 1px solid $gray-E9EAEE;
    margin-left: -$modal-inner-padding;
    margin-right: -$modal-inner-padding;
    padding-left: $modal-inner-padding;
    padding-right: $modal-inner-padding;

    .ant-radio-group {
        padding-top: 8px;
    }
}

.datetime-left,
.datetime-right,
.datetime-center {
    width: 100%;
}

.datetime-left {
    max-width: 31%;
    border-right: 1px solid $gray-E9EAEE;
    padding-top: 16px;
    padding-right: 24px;
    padding-bottom: 24px;
}

.datetime-right {
    max-width: 27%;
    border-left: 1px solid $gray-E9EAEE;
    padding: 24px 15px 24px 24px;
    color: $gray-800;
    max-height: 400px;
}

.datetime-center {
    max-width: 42%;
    padding: 24px;
}

.Calendar {
    box-shadow: none;
    padding-top: 0;
    width: 100%;
    min-height: 30em;
}

.Calendar__header {
    padding: 0 0 16px 0;
    position: relative;
}

.Calendar__monthYearContainer {
    flex: auto;
    min-height: 24px;
    display: flex;
}

.Calendar__monthArrowWrapper {
    transform: none !important;
    height: 20px;

    &.-left {
        position: absolute;
        right: 0px;
        top: 0;

        &:before {
            content: "\35";
        }
    }

    &.-right {
        position: absolute;
        right: 40px;
        top: 0;

        &:before {
            content: "\36";
        }
    }

    &:before {
        font-family: "moneta";
        display: block !important;
    }

    .Calendar__monthArrow {
        display: none;
    }
}

.Calendar__monthYear {
    left: 0;
    transform: none !important;
    position: relative;
    transition: none !important;

    &.-shown {
        line-height: 24px;

        > *:hover {
            background-color: transparent;
        }
    }

    &.-hiddenNext,
    &.-hiddenPrevious {
        visibility: hidden;
        position: absolute;
        left: 2px;
    }

    > *.-activeBackground {
        background-color: transparent;
    }
}

// .Calendar__yearText{
//     pointer-events: none;
//     cursor: default;
// }
.Calendar__monthText,
.Calendar__yearText {
    border: none !important;
    color: $gray-800 !important;
    font-size: 18px !important;
    line-height: 24px !important;
    padding: 0 !important;
    margin-left: 0 !important;
    transform: none !important;
}

.Calendar__yearText {
    margin-left: 8px !important;
}

.Calendar:not(.-noFocusOutline) {
    .Calendar__monthYear {
        &.-shown {
            > *:focus {
                background-color: transparent;
            }
        }
    }
}

.Calendar__monthSelectorItem.-active {
    .Calendar__monthSelectorItemText {
        background-color: $success;
    }
}

.Calendar__weekDays {
    padding: 0;
    font-size: 16px;
    color: $gray-800;
    margin-bottom: 20px;
    line-height: 20px;
    font-weight: 500;
}

.Calendar__section {
    padding: 0;
    color: $gray-800;

    &.-hiddenNext {
        transform: translateX(100%);
    }

    &.-hiddenPrevious {
        transform: translateX(-100%);
    }
}

.Calendar__weekDay {
    width: 32px;
    font-size: 14px;
}

.Calendar__day {
    width: 32px;
    height: 32px;
    position: relative;
    min-height: 32px !important;

    + .Calendar__day {
        margin-left: 25px;
    }

    &.-selected,
    &.-selectedStart,
    &.-selectedEnd {
        background-color: $success;
    }

    &.cal-lightgreen {}

    &.cal-lightgreen:not(.-selectedStart):not(.-selectedBetween):not(.-selectedEnd):not(.-selected) {
        background-color: $lt-green !important;
        color: $ltdk-green !important;
    }
}

.Calendar__weekDay {
    + .Calendar__weekDay {
        margin-left: 25px;
    }
}

.Calendar__weekRow {
    /*justify-content: space-between;*/
}

.Calendar__weekDays {
    justify-content: flex-start;
}

.timezone-block {
    &.open {
        .timezon-area {
            display: block;
        }

        .icon-chevron-down-outline {
            transform: rotate(180deg);
        }
    }
}

.timezon-area {
    border: 1px solid $gray-300;
    border-radius: 6px;
    max-width: 368px;
    width: 100%;
    background-color: $white;
    position: absolute;
    left: 0;
    top: 100%;
    margin-top: 20px;
    display: none;
    z-index: 11;
}

.timezon-head {
    padding: 12px;
    border-bottom: 1px solid $gray-300;

    .search-btn {
        right: auto;
        left: 18px;
    }

    .search-area {
        .form-control {
            padding-right: $input-padding-x;
            padding-left: 45px;
        }
    }
}

.timezon-body {
    padding: 16px 12px 16px 16px;
}

.timezone-type {
    max-height: 170px;
    padding-right: 12px;

    li {
        display: flex;
        justify-content: space-between;

        + li {
            margin-top: 24px;
        }
    }
}

.meeting-detail-modal {
    .btn-close {
        top: 30px;
    }

    .meeting-edit {
        position: absolute;
        right: 46px;
        top: 30px;
    }

    .modal-header {
        align-items: center;
    }
}

.meet-photo-outer {
    min-width: 40px;
    position: relative;
}

.meet-photo {
    width: 40px;
    height: 40px;
    min-width: 40px;
    display: flex;
    border-radius: 100%;
    align-items: center;
    justify-content: center;
    background-color: $gray-300;
    overflow: hidden;

    //color: $white;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }

    em {
        font-size: 20px;
    }
}

.checked-meet {
    width: 12px;
    height: 12px;
    border-radius: 100%;
    border: 1px solid $white;
    background-color: #DCFCE7;
    color: $ltdk-green;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 0;
    bottom: 0;

    em {
        font-size: 8px;
    }
}

.meet-user-block {
    + .meet-user-block {
        margin-top: 12px;
    }
}

.meeting-detail-modal {
    .modal-body {
        line-height: 20px;
    }

    .modal-footer {
        .btn {
            padding: 0;
            line-height: 32px;

            &.btn-outline-secondary,
            &.btn-success,
            &.btn-danger {
                padding: 5px 24px;
            }
        }
    }
}

.calendar-topbar {
    background-color: $white;
}

.ctb-top {
    padding: 8px 24px;
}

.ctb-bottom {
    padding: 8px 24px;
}

.calendar-wrap {
    background-color: $white;
    overflow: hidden;
}

.calendar-body {
    overflow: auto;
}

.column-1 {
    width: 13%;
    min-width: 160px;
}

.column-2 {
    width: 11%;
    min-width: 160px;
}

.column-3 {
    width: 18%;
    min-width: 240px;
}

.column-4 {
    width: 80%;
}

.column-5 {
    width: 21%;
    min-width: 260px;
}

.calendar-head {
    border-bottom: 1px solid $gray-E9EAEE;
    padding-right: 24px;
}

.cal-th {
    padding: 12px 16px;
    font-weight: 500;
}

.calendar-body-tr {
    padding: 12px 24px 12px 0;

    + .calendar-body-tr {
        border-top: 1px solid $gray-E9EAEE;
    }
}

.cal-td {
    padding: 0 16px;
}

.cal-day {
    width: 28px;
    min-width: 28px;
    height: 28px;
    border-radius: 100%;
    background-color: $gray-E9EAEE;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cal-row {
    background-color: $white;
    border: 1px solid transparent;
    border-radius: 6px;
    cursor: pointer;

    + .cal-row {
        margin-top: 8px;
    }

    &.has-open, &:hover {
        border-color: $gray-E9EAEE;
        box-shadow: $dropdown-box-shadow;
    }

    .cal-td {
        padding-top: 6px;
        padding-bottom: 6px;
    }
}

.copy-link-badge {
    position: absolute;
    right: 0;
    top: 100%;
    margin-top: 8px;
    padding: 2px 14px;
    color: $ltdk-green;
    z-index: 1;
    background-color: $lt-green;
    border-radius: 24px;
    min-width: 260px;
    font-weight: 500;
}

.calendar-responsive {
    overflow-x: auto;
    overflow-y: hidden;
}

.calendar-table {
    min-width: 1200px;

    .meet-photo {
        width: 24px;
        height: 24px;
        min-width: 24px;
    }

    .text-end {
        .text-muted {
            color: $gray-500 !important;
        }
    }
}

.erd-time {
    color: $gray-500;
}

.erd-right {
    border-left: 1px solid $gray-E9EAEE;
}

.erd-block {
    padding: 16px 24px;
}

.erd-right {
    padding: 16px 24px;
}

.room-list {
    max-height: 180px;
    margin-right: -8px;
    padding-right: 8px;

    li {
        + li {
            margin-top: 16px;
        }
    }
}

.ipm-block {
    border: 1px solid $gray-300;
    border-radius: 6px;
}

// Res
@include media-breakpoint-down(xl) {
    .datetime-left {
        max-width: 45%;
    }

    .datetime-center {
        max-width: 55%;
    }

    .datetime-right {
        max-width: 100%;
        border-left: none;
        padding-left: 0;
        border-top: 1px solid $gray-E9EAEE;
    }

    .Calendar__day {
        + .Calendar__day {
            margin-left: 14px;
        }
    }

    .Calendar__weekDay {
        + .Calendar__weekDay {
            margin-left: 14px;
        }
    }
}


@include media-breakpoint-down(lg) {
    .datetime-left,
    .datetime-center {
        max-width: 100%;
    }

    .datetime-left {
        border-right: none;
        padding-right: 0;
        display: none;
    }

    .datetime-center {
        padding-left: 0;
        padding-right: 0;
    }

    .ant-steps {
        display: none !important;
    }

    .new-meet-modal {
        .btn-close {
            display: none;
        }
    }

    .Calendar__day {
        + .Calendar__day {
            margin-left: 30px;
        }
    }

    .Calendar__weekDay {
        + .Calendar__weekDay {
            margin-left: 30px;
        }
    }
}


@include media-breakpoint-down(md) {
    .datetime-step {
        border-top: none;
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
    }

    .new-meet-back {
        position: absolute;
        left: 20px;
        top: 22px;
        z-index: 11;
    }

    .datetime-right {
        border-top: none;
        padding-top: 0;
    }

    .meeting-detail-modal {
        .meeting-edit {
            top: 26px;
        }

        .modal-footer {
            display: flex;

            .btn {
                width: auto;

                + .btn {
                    margin-left: 16px;
                    margin-top: 0;
                }
            }
        }
    }

    .copy-link-badge {
        margin-top: 8px;
    }

    .ctb-bottom {
        padding-top: 12px;
        padding-bottom: 12px;
    }

    .column-1 {
        min-width: auto;
        width: auto;
    }

    .cal-mob-row {
        border: 1px solid $success;
        border-radius: 6px;
        padding: 12px;
        background-color: $gray-400;

        + .cal-mob-row {
            margin-top: 8px;
        }
    }

    .calendar-table {
        min-width: auto;

        .meet-photo {
            border: 1px solid $white;
        }
    }

    .cal-day {
        font-size: 14px;
    }

    .outlook-16 {
        width: 16px;
        height: 16px;
        background-color: $white;
        border-radius: 100%;
        display: flex;
        align-content: center;
        justify-content: center;

        img {
            width: 10px;
        }
    }

    .calendar-body-tr {
        + .calendar-body-tr {
            border: none;
        }
    }

    .erd-mob-block {
        padding: 16px 24px;
    }

    .max-w-emd {
        max-width: calc(100vw - 135px);
    }

    .Calendar__weekDays {
        margin: 0 auto;
    }

    .Calendar__sectionWrapper {
        width: 272px;
        margin: 0 auto;
    }

    .Calendar__day {
        + .Calendar__day {
            margin-left: 8px;
        }
    }

    .Calendar__weekDay {
        + .Calendar__weekDay {
            margin-left: 8px;
        }
    }

    .timezon-area {
        width: 100%;
    }
}


.datetime-center {
    .Calendar__yearText {
        //display: none;
        pointer-events: none;
        cursor: default;
    }

    .Calendar__monthText {
        pointer-events: none;
        cursor: default;
    }
}

.Calendar__header {
    .Calendar__yearText {
        pointer-events: none;
        cursor: default;
    }

    .Calendar__monthText {
        pointer-events: none;
        cursor: default;
    }
}

.min-width {
    min-width: 160px;
    text-align: left;
}

.calendar-date-dot {
    &::after {
        content: "";
        position: absolute;
        left: 50%;
        bottom: 2px;
        margin-left: -3px;
        width: 6px;
        height: 6px;
        border-radius: 6px;
        background-color: $success;
    }
}

.Calendar__day.-today:not(.-selectedStart):not(.-selectedEnd):not(.-selectedBetween)::after {
    opacity: 0;
}
.Calendar__day.-ltr.-selectedStart, .Calendar__day.-ltr.-selectedEnd, .Calendar__day.-ltr.-selectedBetween{
    border-radius: 50% 
}

.meeting-details-main-div{
    font-family: Calibri, Arial, 
    Helvetica, sans-serif; font-size: 12pt; 
    color: rgb(0, 0, 0);
}
.div-width-height{
    width:100%;
    height: 20px;
}
.span-color-wrap{
    white-space:nowrap;color:#5F5F5F;opacity:.36;
}

.meeting-details-font-family{
    font-family:'Segoe UI','Helvetica Neue',
    Helvetica,Arial,sans-serif;
}

.meeting-details-mb-20{
    margin-bottom: 20px;
}

.meeting-details-mt-20{
    margin-top:20px
}
.meeting-details-mt-0{
    margin-top: 0px;
}
.meeting-details-mb-0{
    margin-bottom: 0px
}
.meeting-details-mb-4{
    margin-bottom:4px
}

.meeting-details-mb-24{
    margin-bottom:24px
}
.meeting-details-mt-24{
    margin-top:24px
}


.anchor-design{
    text-decoration: underline;color:
     #6264a7;
}


.meeting-info-color{
    color:#252424;
}

.wrap-text{
    word-break: break-word;
    white-space: pre-wrap;
}



