// Tanner Fry
// tfry@monetagroup.com
// General Styles for the application.

// MUI Theme Overrides
.MuiButtonBase-root.MuiButton-root.MuiButton-contained.MuiButton-containedPrimary {
    background-color: #41BA77;
    border: 1px solid #41BA77;
    box-shadow: none;

    &:hover {
        background-color: #5ec48b;
        border: 1px solid #5ec48b;
        box-shadow: none;
    }
    &.disabled {
        cursor: not-allowed;
        pointer-events: unset;
        box-shadow: none;
        opacity: 0.65;
    }
}

// Modals
.mc-modal {

    & > .MuiBox-root {
        background-color: $white;
        color: $gray-900;
        border-radius: 5px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
        padding: 1em;
        position: relative;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;
        max-width: 50%;
        max-height: 80%;
        margin: 0 auto;
    }

    // Content
    .mc-modal-title {
        font-size: 1.5rem;
        font-weight: bold;
    }
    .mc-modal-description {
        font-size: 0.9rem;
    }

    // Buttons
    .exit-button {
        position: absolute;
        top: 0;
        right: 0;
        margin: 1em;
        cursor: pointer;
        color: $gray-500;
    }
    .mc-footer-buttons {
        display: flex;
        justify-content: flex-end;
        margin-top: 1em;

        button {
            text-transform: capitalize;
            margin-left: 1em;

            &.primary {
                background-color: $green;
                border: 1px solid $green;
                color: $white;

                &:hover {
                    background-color: $teal;
                    border: 1px solid $teal;
                }
            }
            &.secondary {
                background-color: $white;
                border: 1px solid $gray-300;
                color: $gray-900;
                font-weight: bold;

                &:hover {
                    background-color: $gray-100;
                }
            }
        }
    }

    // Misc styling
    .MuiModal-backdrop {
        opacity: 0.5;
    }
}
.mc-modal.mc-modal-small {

    & > .MuiBox-root {
        max-width: 30%;
    }
}
.mc-modal.mc-modal-xsmall {

    & > .MuiBox-root {
        max-width: 20%;
    }
}

.modal-general-error {

    .modal-title {

        .icon-32 {
            color: #db5c5c;
        }
    }
}

// Spinners
.loading-spinner-container {
    // position: relative;
    // height: 100%;
    // width: 100%;

    .loading-spinner {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
        background-color: unset;
        z-index: 9999;

        .spinner-container {
            background-color: #284254;
            border-radius: 5px;
            border: 1px solid #243b4c;
            display: flex;
            justify-content: center;
            align-items: center;
            padding: 1em;

            .spinner {
                width: 48px;
                height: 48px;
                border: 5px solid #41BA77;
                border-bottom-color: transparent;
                border-radius: 50%;
                display: inline-block;
                box-sizing: border-box;
                animation: rotation 1s linear infinite;
            }
        }
        
        @keyframes rotation {
            0% {
                transform: rotate(0deg);
            }
            100% {
                transform: rotate(360deg);
            }
        } 
    }
    .background-dimmer {
        background-color: #000;
        opacity: 0.5;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 9998;
    }
}