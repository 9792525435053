@charset "UTF-8";

@font-face {
  font-family: "moneta";
  src:url("fonts/moneta.eot");
  src:url("fonts/moneta.eot?#iefix") format("embedded-opentype"),
    url("fonts/moneta.woff") format("woff"),
    url("fonts/moneta.ttf") format("truetype"),
    url("fonts/moneta.svg#moneta") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "moneta" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "moneta" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  /*line-height: 1;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: inline-block;
    vertical-align: top;
}

.icon-add-circle-filled:before {
  content: "\61";
}
.icon-logout-filled:before {
  content: "\62";
}
.icon-add-circle-outline:before {
  content: "\63";
}
.icon-add-outline:before {
  content: "\64";
}
.icon-adjustments-filled:before {
  content: "\65";
}
.icon-adjustments-filled-1:before {
  content: "\66";
}
.icon-adjustments-outline:before {
  content: "\67";
}
.icon-annotation-filled:before {
  content: "\68";
}
.icon-annotation-outline:before {
  content: "\69";
}
.icon-archive-filled:before {
  content: "\6a";
}
.icon-archive-outline:before {
  content: "\6b";
}
.icon-arrow-circle-down-filled:before {
  content: "\6c";
}
.icon-arrow-circle-down-outline:before {
  content: "\6d";
}
.icon-arrow-circle-left-filled:before {
  content: "\6e";
}
.icon-arrow-sm-down-outline:before {
  content: "\6f";
}
.icon-arrow-right-outline:before {
  content: "\70";
}
.icon-arrow-narrow-up-outline:before {
  content: "\71";
}
.icon-arrow-narrow-right-outline:before {
  content: "\72";
}
.icon-arrow-narrow-left-outline:before {
  content: "\73";
}
.icon-arrow-narrow-down-outline:before {
  content: "\74";
}
.icon-arrow-left-outline:before {
  content: "\75";
}
.icon-arrow-down-outline:before {
  content: "\76";
}
.icon-arrow-circle-up-outline:before {
  content: "\77";
}
.icon-arrow-circle-up-filled:before {
  content: "\78";
}
.icon-arrow-circle-right-outline:before {
  content: "\79";
}
.icon-arrow-circle-right-filled:before {
  content: "\7a";
}
.icon-arrow-circle-left-outline:before {
  content: "\41";
}
.icon-arrow-sm-left-outline:before {
  content: "\42";
}
.icon-arrow-sm-right-outline:before {
  content: "\43";
}
.icon-arrow-sm-up-outline:before {
  content: "\44";
}
.icon-arrow-up-outline:before {
  content: "\45";
}
.icon-at-symbol-outline:before {
  content: "\46";
}
.icon-badge-check-filled:before {
  content: "\47";
}
.icon-badge-check-outline:before {
  content: "\48";
}
.icon-ban-outline:before {
  content: "\49";
}
.icon-bell-filled:before {
  content: "\4a";
}
.icon-bell-outline:before {
  content: "\4b";
}
.icon-bookmark-alt-filled:before {
  content: "\4c";
}
.icon-bookmark-alt-outline:before {
  content: "\4d";
}
.icon-bookmark-filled:before {
  content: "\4e";
}
.icon-check-circle-outline:before {
  content: "\4f";
}
.icon-check-circle-filled:before {
  content: "\50";
}
.icon-chat-outline:before {
  content: "\51";
}
.icon-chat-filled:before {
  content: "\52";
}
.icon-chat-alt-outline:before {
  content: "\53";
}
.icon-chat-alt-filled:before {
  content: "\54";
}
.icon-chat-alt-2-outline:before {
  content: "\55";
}
.icon-chat-alt-2-filled:before {
  content: "\56";
}
.icon-camera-outline:before {
  content: "\57";
}
.icon-camera-filled:before {
  content: "\58";
}
.icon-calendar-outline:before {
  content: "\59";
}
.icon-bookmark-outline:before {
  content: "\5a";
}
.icon-calendar-filled:before {
  content: "\30";
}
.icon-check-outline:before {
  content: "\31";
}
.icon-clipboard-check-outline:before {
  content: "\32";
}
.icon-clipboard-check-filled:before {
  content: "\33";
}
.icon-chevron-up-outline:before {
  content: "\34";
}
.icon-chevron-right-outline:before {
  content: "\35";
}
.icon-chevron-left-outline:before {
  content: "\36";
}
.icon-chevron-down-outline:before {
  content: "\37";
}
.icon-clipboard-copy-filled:before {
  content: "\38";
}
.icon-clipboard-copy-outline:before {
  content: "\39";
}
.icon-clipboard-filled:before {
  content: "\21";
}
.icon-clipboard-list-filled:before {
  content: "\22";
}
.icon-clipboard-list-outline:before {
  content: "\23";
}
.icon-clipboard-outline:before {
  content: "\24";
}
.icon-cursor-click-outline:before {
  content: "\25";
}
.icon-cursor-click-filled:before {
  content: "\26";
}
.icon-combined-shape-outline:before {
  content: "\27";
}
.icon-cog-outline:before {
  content: "\28";
}
.icon-cog-filled:before {
  content: "\29";
}
.icon-cloud-upload-outline:before {
  content: "\2a";
}
.icon-cloud-upload-filled:before {
  content: "\2b";
}
.icon-cloud-outline:before {
  content: "\2c";
}
.icon-cloud-filled:before {
  content: "\2d";
}
.icon-cloud-download-outline:before {
  content: "\2e";
}
.icon-cloud-download-filled:before {
  content: "\2f";
}
.icon-clock-outline:before {
  content: "\3a";
}
.icon-clock-filled:before {
  content: "\3b";
}
.icon-desktop-computer-filled:before {
  content: "\3c";
}
.icon-desktop-computer-outline:before {
  content: "\3d";
}
.icon-document-add-filled:before {
  content: "\3e";
}
.icon-document-add-outline:before {
  content: "\3f";
}
.icon-document-download-filled:before {
  content: "\40";
}
.icon-document-download-outline:before {
  content: "\5b";
}
.icon-document-duplicate-filled:before {
  content: "\5d";
}
.icon-document-duplicate-outline:before {
  content: "\5e";
}
.icon-document-filled:before {
  content: "\5f";
}
.icon-document-outline:before {
  content: "\60";
}
.icon-document-remove-filled:before {
  content: "\7b";
}
.icon-document-remove-outline:before {
  content: "\7c";
}
.icon-document-report-filled:before {
  content: "\7d";
}
.icon-duplicate-filled:before {
  content: "\7e";
}
.icon-download-filled:before {
  content: "\5c";
}
.icon-double-arrow-up-outline:before {
  content: "\e000";
}
.icon-double-arrow-right-outline:before {
  content: "\e001";
}
.icon-double-arrow-left-outline:before {
  content: "\e002";
}
.icon-double-arrow-down-outline:before {
  content: "\e003";
}
.icon-dots-vertical-outline:before {
  content: "\e004";
}
.icon-dots-circle-horizontal-outline:before {
  content: "\e005";
}
.icon-dots-circle-horizontal-filled:before {
  content: "\e006";
}
.icon-dots-horizontal-outline:before {
  content: "\e007";
}
.icon-document-text-outline:before {
  content: "\e008";
}
.icon-document-text-filled:before {
  content: "\e009";
}
.icon-document-report-outline:before {
  content: "\e00a";
}
.icon-duplicate-outline:before {
  content: "\e00b";
}
.icon-exclamation-circle-filled:before {
  content: "\e00c";
}
.icon-exclamation-circle-outline:before {
  content: "\e00d";
}
.icon-exclamation-filled:before {
  content: "\e00e";
}
.icon-exclamation-outline:before {
  content: "\e00f";
}
.icon-external-link-outline:before {
  content: "\e010";
}
.icon-eye-hide-filled:before {
  content: "\e011";
}
.icon-eye-hide-outline:before {
  content: "\e012";
}
.icon-eye-reveal-filled:before {
  content: "\e013";
}
.icon-eye-reveal-outline:before {
  content: "\e014";
}
.icon-fast-forward-filled:before {
  content: "\e015";
}
.icon-fast-forward-outline:before {
  content: "\e016";
}
.icon-flag-filled:before {
  content: "\e017";
}
.icon-globe-filled:before {
  content: "\e018";
}
.icon-globe-alt-outline:before {
  content: "\e019";
}
.icon-folder-remove-outline:before {
  content: "\e01a";
}
.icon-folder-remove-filled:before {
  content: "\e01b";
}
.icon-folder-outline:before {
  content: "\e01c";
}
.icon-folder-open-outline:before {
  content: "\e01d";
}
.icon-folder-open-filled:before {
  content: "\e01e";
}
.icon-folder-filled:before {
  content: "\e01f";
}
.icon-folder-download-outline:before {
  content: "\e020";
}
.icon-folder-download-filled:before {
  content: "\e021";
}
.icon-folder-add-outline:before {
  content: "\e022";
}
.icon-folder-add-filled:before {
  content: "\e023";
}
.icon-flag-outline:before {
  content: "\e024";
}
.icon-globe-outline:before {
  content: "\e025";
}
.icon-group-filled:before {
  content: "\e026";
}
.icon-group-outline:before {
  content: "\e027";
}
.icon-hand-filled:before {
  content: "\e028";
}
.icon-hand-outline:before {
  content: "\e029";
}
.icon-heart-filled:before {
  content: "\e02a";
}
.icon-heart-outline:before {
  content: "\e02b";
}
.icon-home-filled:before {
  content: "\e02c";
}
.icon-home-outline:before {
  content: "\e02d";
}
.icon-image-filled:before {
  content: "\e02e";
}
.icon-image-outline:before {
  content: "\e02f";
}
.icon-inbox-filled:before {
  content: "\e030";
}
.icon-inbox-in-filled:before {
  content: "\e031";
}
.icon-login-filled:before {
  content: "\e032";
}
.icon-lock-outline:before {
  content: "\e033";
}
.icon-lock-filled:before {
  content: "\e034";
}
.icon-link-outline:before {
  content: "\e035";
}
.icon-lightning-bolt-outline:before {
  content: "\e036";
}
.icon-lightning-bolt-filled:before {
  content: "\e037";
}
.icon-light-bulb-outline:before {
  content: "\e038";
}
.icon-light-bulb-filled:before {
  content: "\e039";
}
.icon-info-circle-outline:before {
  content: "\e03a";
}
.icon-info-circle-filled:before {
  content: "\e03b";
}
.icon-inbox-outline:before {
  content: "\e03c";
}
.icon-inbox-in-outline:before {
  content: "\e03d";
}
.icon-mail-filled:before {
  content: "\e03e";
}
.icon-mail-open-filled:before {
  content: "\e03f";
}
.icon-mail-open-outline:before {
  content: "\e040";
}
.icon-mail-outline:before {
  content: "\e041";
}
.icon-map-filled:before {
  content: "\e042";
}
.icon-map-outline:before {
  content: "\e043";
}
.icon-marker-filled:before {
  content: "\e044";
}
.icon-marker-outline:before {
  content: "\e045";
}
.icon-menu-alt-1-outline:before {
  content: "\e046";
}
.icon-menu-alt-2-outline:before {
  content: "\e047";
}
.icon-menu-alt-3-outline:before {
  content: "\e048";
}
.icon-menu-alt-4-outline:before {
  content: "\e049";
}
.icon-menu-outline:before {
  content: "\e04a";
}
.icon-phone-incoming-filled:before {
  content: "\e04b";
}
.icon-phone-filled:before {
  content: "\e04c";
}
.icon-pencil-outline:before {
  content: "\e04d";
}
.icon-pencil-filled:before {
  content: "\e04e";
}
.icon-pencil-alt-outline:before {
  content: "\e04f";
}
.icon-pencil-alt-filled:before {
  content: "\e050";
}
.icon-pause-outline:before {
  content: "\e051";
}
.icon-pause-filled:before {
  content: "\e052";
}
.icon-paper-clip-outline:before {
  content: "\e053";
}
.icon-office-outline:before {
  content: "\e054";
}
.icon-office-filled:before {
  content: "\e055";
}
.icon-microphone-outline:before {
  content: "\e056";
}
.icon-microphone-filled:before {
  content: "\e057";
}
.icon-phone-incoming-outline:before {
  content: "\e058";
}
.icon-phone-missed-call-filled:before {
  content: "\e059";
}
.icon-phone-missed-call-outline:before {
  content: "\e05a";
}
.icon-phone-outgoing-filled:before {
  content: "\e05b";
}
.icon-phone-outgoing-outline:before {
  content: "\e05c";
}
.icon-phone-outline:before {
  content: "\e05d";
}
.icon-play-filled:before {
  content: "\e05e";
}
.icon-printer-filled:before {
  content: "\e05f";
}
.icon-printer-outline:before {
  content: "\e060";
}
.icon-question-mark-circle-filled:before {
  content: "\e061";
}
.icon-question-mark-circle-outline:before {
  content: "\e062";
}
.icon-refresh-outline:before {
  content: "\e063";
}
.icon-reply-outline:before {
  content: "\e064";
}
.icon-share-outline:before {
  content: "\e065";
}
.icon-share-filled:before {
  content: "\e066";
}
.icon-selector-outline:before {
  content: "\e067";
}
.icon-search-outline:before {
  content: "\e068";
}
.icon-save-outline:before {
  content: "\e069";
}
.icon-save-filled:before {
  content: "\e06a";
}
.icon-save-as-outline:before {
  content: "\e06b";
}
.icon-save-as-filled:before {
  content: "\e06c";
}
.icon-rss-outline:before {
  content: "\e06d";
}
.icon-rewind-outline:before {
  content: "\e06e";
}
.icon-rewind-filled:before {
  content: "\e06f";
}
.icon-resize-expand-outline:before {
  content: "\e070";
}
.icon-resize-collapse-outline:before {
  content: "\e071";
}
.icon-shield-check-filled:before {
  content: "\e072";
}
.icon-shield-check-outline:before {
  content: "\e073";
}
.icon-shield-exclamation-filled:before {
  content: "\e074";
}
.icon-shield-exclamation-outline:before {
  content: "\e075";
}
.icon-signature-filled:before {
  content: "\e076";
}
.icon-sort-ascending-outline:before {
  content: "\e077";
}
.icon-sort-descending-outline:before {
  content: "\e078";
}
.icon-sparkles-filled:before {
  content: "\e079";
}
.icon-sparkles-outline:before {
  content: "\e07a";
}
.icon-star-filled:before {
  content: "\e07b";
}
.icon-star-outline:before {
  content: "\e07c";
}
.icon-stop-filled:before {
  content: "\e07d";
}
.icon-stop-outline:before {
  content: "\e07e";
}
.icon-unlock-outline:before {
  content: "\e07f";
}
.icon-unlock-filled:before {
  content: "\e080";
}
.icon-trash-outline:before {
  content: "\e081";
}
.icon-trash-filled:before {
  content: "\e082";
}
.icon-thumb-up-outline:before {
  content: "\e083";
}
.icon-thumb-up-filled:before {
  content: "\e084";
}
.icon-thumb-down-outline:before {
  content: "\e085";
}
.icon-thumb-down-filled:before {
  content: "\e086";
}
.icon-switch-vertical-outline:before {
  content: "\e087";
}
.icon-switch-horizontal-outline:before {
  content: "\e088";
}
.icon-subtract-outline:before {
  content: "\e089";
}
.icon-subtract-circle-outline:before {
  content: "\e08a";
}
.icon-subtract-circle-filled:before {
  content: "\e08b";
}
.icon-upload-filled:before {
  content: "\e08c";
}
.icon-user-add-filled:before {
  content: "\e08d";
}
.icon-user-add-outline:before {
  content: "\e08e";
}
.icon-user-circle-filled:before {
  content: "\e08f";
}
.icon-user-circle-outline:before {
  content: "\e090";
}
.icon-user-filled:before {
  content: "\e091";
}
.icon-user-outline:before {
  content: "\e092";
}
.icon-user-remove-filled:before {
  content: "\e093";
}
.icon-user-remove-outline:before {
  content: "\e094";
}
.icon-users-filled:before {
  content: "\e095";
}
.icon-users-outline:before {
  content: "\e096";
}
.icon-video-camera-filled:before {
  content: "\e097";
}
.icon-video-camera-outline:before {
  content: "\e098";
}
.icon-zoom-out-outline:before {
  content: "\e099";
}
.icon-zoom-in-outline:before {
  content: "\e09a";
}
.icon-x-outline:before {
  content: "\e09b";
}
.icon-x-circle-outline:before {
  content: "\e09c";
}
.icon-x-circle-filled:before {
  content: "\e09d";
}
.icon-wifi-outline:before {
  content: "\e09e";
}
.icon-volume-up-outline:before {
  content: "\e09f";
}
.icon-volume-up-filled:before {
  content: "\e0a0";
}
.icon-volume-off-outline:before {
  content: "\e0a1";
}
.icon-volume-off-filled:before {
  content: "\e0a2";
}
.icon-view-list-outline:before {
  content: "\e0a3";
}
.icon-view-grid-outline:before {
  content: "\e0a4";
}
.icon-view-grid-filled:before {
  content: "\e0a5";
}
.icon-compliance:before {
  content: "\e0a6";
}
