@charset "UTF-8";

@font-face {
  font-family: "moneta-sendbird";
  src:url("fonts/moneta-sendbird.eot");
  src:url("fonts/moneta-sendbird.eot?#iefix") format("embedded-opentype"),
    url("fonts/moneta-sendbird.woff") format("woff"),
    url("fonts/moneta-sendbird.ttf") format("truetype"),
    url("fonts/moneta-sendbird.svg#moneta-sendbird") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "moneta-sendbird" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="sb-"]:before,
[class*=" sb-"]:before {
  font-family: "moneta-sendbird" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  /*line-height: 1;*/
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.sb-checkbox-off:before {
  content: "\6d";
}
.sb-add:before {
  content: "\61";
}
.sb-archive:before {
  content: "\62";
}
.sb-arrow-left:before {
  content: "\63";
}
.sb-attach:before {
  content: "\64";
}
.sb-back:before {
  content: "\65";
}
.sb-broadcast:before {
  content: "\66";
}
.sb-camera:before {
  content: "\67";
}
.sb-chat:before {
  content: "\68";
}
.sb-chat-filled:before {
  content: "\69";
}
.sb-chat-hide:before {
  content: "\6a";
}
.sb-chat-show:before {
  content: "\6b";
}
.sb-ban:before {
  content: "\6c";
}
.sb-chevron-down:before {
  content: "\6e";
}
.sb-chevron-right:before {
  content: "\6f";
}
.sb-close:before {
  content: "\70";
}
.sb-collapse:before {
  content: "\71";
}
.sb-copy:before {
  content: "\72";
}
.sb-create:before {
  content: "\73";
}
.sb-delete:before {
  content: "\74";
}
.sb-document:before {
  content: "\75";
}
.sb-done:before {
  content: "\76";
}
.sb-done-all:before {
  content: "\77";
}
.sb-download:before {
  content: "\78";
}
.sb-edit:before {
  content: "\79";
}
.sb-moderations:before {
  content: "\7a";
}
.sb-message:before {
  content: "\41";
}
.sb-members:before {
  content: "\42";
}
.sb-leave:before {
  content: "\43";
}
.sb-info:before {
  content: "\44";
}
.sb-gif:before {
  content: "\45";
}
.sb-freeze:before {
  content: "\46";
}
.sb-filled:before {
  content: "\47";
}
.sb-file-document:before {
  content: "\48";
}
.sb-file-audio:before {
  content: "\49";
}
.sb-expand:before {
  content: "\4a";
}
.sb-error:before {
  content: "\4b";
}
.sb-emojis-more:before {
  content: "\4c";
}
.sb-more:before {
  content: "\4d";
}
.sb-mute:before {
  content: "\4e";
}
.sb-notifications:before {
  content: "\4f";
}
.sb-notifications-filled:before {
  content: "\50";
}
.sb-notifications-off:before {
  content: "\51";
}
.sb-operator:before {
  content: "\52";
}
.sb-photo:before {
  content: "\53";
}
.sb-play:before {
  content: "\54";
}
.sb-plus:before {
  content: "\55";
}
.sb-question:before {
  content: "\56";
}
.sb-refresh:before {
  content: "\57";
}
.sb-reply:before {
  content: "\58";
}
.sb-search:before {
  content: "\59";
}
.sb-send:before {
  content: "\5a";
}
.sb-spinner:before {
  content: "\30";
}
.sb-streaming:before {
  content: "\31";
}
.sb-supergroup:before {
  content: "\32";
}
.sb-thumbnail-none:before {
  content: "\33";
}
.sb-unarchive:before {
  content: "\34";
}
.sb-user:before {
  content: "\35";
}
