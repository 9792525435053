.beta-tag {
    background-color: $lt-green;
    color: $ltdk-green;
    font-size: 12px;
    font-weight: 500;
    padding: 2px 8px;
    border-radius: 24px;
    position: absolute;
    top: 5%;
    right: 25%;
}
.new-message-validation-popover {

    .MuiPaper-root.MuiPaper-elevation.MuiPaper-rounded {
        color: $notif-error-color;
        border: 1px solid $notif-error-border-color;
        padding: 0 5px;
    }
}
.message-footer-popper.MuiPopper-root {
    color: $notif-info-color;
    border: 1px solid $notif-info-border-color;
    border-radius: 5px 5px 0 0;
    box-shadow: unset;
    background-color: $notif-info-bg-color;
    padding: 0 5px;
    transform: translate(0, -100%);
    margin-left: 24px !important;
}
.twilio-conversations-container {

    .twilio-conversations-header {
        position: relative;
        height: 77px;
        .advisor-number {
            position: absolute;
            top: 0;
            left: 50%;
            transform: translateX(-50%);
            background-color: #41BA77;
            padding: 0 10px;
            border-bottom: 1px solid #284254;
            border-left: 1px solid #284254;
            border-right: 1px solid #284254;
            border-radius: 0 0 10px 10px;
            color: #fff;
        }
        .conversation-filters {
            display: flex;

            .search-area {
            
                width: 100%;
    
                input {
                    padding-right: 45px;
                    padding-left: 45px;
                }
                .search-btn {
                    position: absolute;
                    top: 24px;
                    left: 16px;
                }
                .close-search-btn {
                    position: absolute;
                    top: 34px;
                    right: 8px;
                }
            }
        }
    }
    .twilio-conversations-content {
        height: calc(100% - 77px);
        min-height: 420px;
        @include media-breakpoint-down(md) {
            flex-direction: column;
        }
    }
}
.twilio-conversations-list {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    border-right: 1px solid #E9EAEE;
    .conversations-header {
        width: 320px;
        z-index: 11;
        background-color: #F4F4F6;
        padding: 16px 24px;
        border-bottom: 1px solid #E9EAEE;
        height: 10%;
    }
    .conversations-list {
        height: 90%;
        background-color: #F4F4F6;
        overflow-y: auto;
        
        .no-conversations {
            display: flex;
            flex-wrap: wrap;
            font-size: 16px;
            font-weight: 500;
            text-align: center;
            margin-top: 50%;
            
            > * {
                flex-basis: 100%;
            }
            h5 {
                color: #b5b6b9;
            }
        }
        .conversation-item {
            display: flex;
            padding: 12px 16px;
            border-bottom: 1px solid #FFFFFF;
            cursor: pointer;
            position: relative;
        
            &:hover {
                background-color: #FFFFFF;
            }
            &.active {
                background-color: #D9F1E4;

                &:before {
                    content: "";
                    background-color: #41BA77;
                    position: absolute;
                    left: 0;
                    top: 0;
                    bottom: 0;
                    width: 4px;
                    z-index: 1;
                }
            }
            .conversation-item-image {
                width: 56px;
                height: 56px;
                min-width: 56px;
                border-radius: 100%;
                overflow: hidden;
                background-color: #D3D6DE;
                display: flex;
                align-items: center;
                justify-content: center;
                img {
                    height: 100%;
                    width: 100%;
                    object-fit: cover;
                }
            }
            .conversation-item-details {
                flex: 1 1 auto;
                font-family: 'Segoe UI';
                padding-left: 16px;
                width: calc(100% - 104px);
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            
                .dropdown {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 14px;

                    .icon-32:hover {
                        background-color: #94dab3;
                        border-radius: 5px;
                    }
                }
                h6 {
                    font-weight: 600;
                    margin: 0;
                    line-height: 20px;
                    color: $body-color;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: calc(100% - 106px);
                }
                p {
                    line-height: 20px;
                    margin-bottom: 0;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                }
                .message-read {
                    display: flex;
                    align-items: end;
                    color: $gray-500;
                    font-size: 14px;
                    line-height: 14px;
                    white-space: nowrap;
                    margin-right: .25rem;
                }
                .message-time {
                    color: $gray-500;
                    font-size: 14px;
                    line-height: 14px;
                    white-space: nowrap;
                }
                .last-message-content {
                    margin: 0;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    flex-basis: calc(100% - 30px);
                }
                .unread-message-count {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    min-width: 20px;
                    width: 20px;
                    height: 20px;
                    line-height: 19px;
                    color: #fff;
                    border-radius: 100%;
                    font-size: 12px;
                    background-color: #399B6F;
                }
            }
        }
    }
}
.twilio-conversations-messages {
    height: 100%;
    background-color: #fff;
    display: grid;
    grid-template-rows: max-content;
    align-items: stretch;
    
    .messages-header {
        height: 10%;
        border-bottom: 1px solid #E9EAEE;
        padding: 16px 24px;
        height: fit-content;
        .conversation-actions {
            span {
                margin-left: 8px;
            }
            .icon-info-circle-outline {
                color: #41BA77;
            }
        }
    }
    .messages-list {
        
        overflow-y: auto;
        padding: 16px 24px;

        .no-messages {
            display: flex;
            flex-wrap: wrap;
            align-content: center;
            align-items: center;
            justify-content: center;
            height: 100%;
            width: 100%;

            > * {
                flex-basis: 100%;
                text-align: center;
            }
            > .anticon {
                height: 64px;
                width: 64px;
                color: #41BA77;

                svg {
                    height: 100%;
                    width: 100%;
                }
            }
            > h5 {
                font-size: 1.2rem;
                font-weight: 500;
                color: #b5b6b9;
            }
        }
        .message-item {
            margin: 16px 0;
            &.highlighted {
                position: relative;
                &::before {
                    content: '';
                    position: absolute;
                    left: -.5rem;
                    top: -.5rem;
                    height: calc(100% + 1rem);
                    width: calc(100% + 1rem);
                    background-color: rgba(65, 186, 119, .25);
                    z-index: 0;
                    border-radius: 8px;
                }
            }
            &.current-user {
                display: flex;
                justify-content: flex-end;

                .message-item-details {
                    display: flex;
                    flex-direction: column;
                    flex-basis: 100%;
                    align-items: flex-end;
                    padding-left: 16px;

                    h6 {
                        flex-basis: 100%;
                    }
                    .message-content {
                        display: flex;
                        align-items: flex-end;
                        max-width: 75%;

                        .message-body-wrapper {
                            background-color: #41BA77;
                            color: #fff;
                            border-radius: 10px;
                            padding: 8px;
                            width: fit-content;
                            margin-left: 8px;
                            position: relative;
                            .reaction-cont {
                                left: -.5em;
                            }
                            .message-body {
                                display: flex;
                                flex-direction: row;
                                flex-wrap: wrap;
                                align-items: center;
                                
                                //word-break: break-all;

                                a {
                                    color: #fff;
                                    text-decoration: underline;
                                }
                                // If a has tc-media, then media should show on its own line
                                a:has(.tc-media.tc-video) {
                                    display: flex;
                                    flex-basis: 100%;
                                }
                                .tc-media {
                                    width: 100%;
                                    max-width: 400px;
                                    max-height: 400px;
                                    border-radius: 10px;
                                }
                                .tc-media.tc-video {
                                    max-width: 100%;
                                    max-height: 400px;
                                }
                                .icon-as-link {
                                    display: flex;
                                    align-content: center;
                                    justify-content: center;
                                    background-color: #fff;
                                    height: 28px;
                                    width: 28px;
                                    border-radius: 6px;
                                    padding: 2px;
                                    svg {
                                        width: 20px;
                                        height: 24px;
                                        fill: #41BA77;
                                    }
                                }
                                .file-name-text-as-link {
                                    //width: 100%;
                                    margin-left: 8px;
                                    white-space: wrap;
                                    text-decoration: none;
                                    max-width: calc(100% - 36px);
                                }
                            }
                            .message-sms-media {
                                border-radius: 10px;
                                border: 1px solid #41BA77;
                                overflow: hidden;

                                .message-sms-media-item {
                                    cursor: pointer;
                                }
                                img {
                                    max-height: 400px;
                                    max-width: 400px;
                                    object-fit: contain;
                                }
                            }
                        }
                        .message-time {
                            color: #8089b1;
                            font-size: 14px;
                            line-height: 14px;
                            white-space: nowrap;
                        }
                        .message-read {
                            display: flex;
                            align-items: end;
                            color: $gray-500;
                            font-size: 16px;
                            line-height: 16px;
                            white-space: nowrap;
                            margin-right: .25rem;
                        }
                    }
                }
            }
            // .message-item-image {

            // }
            &:not(.current-user) {

                .message-item-details {
                    display: flex;
                    flex-wrap: wrap;
    
                    .message-author {
                        display: flex;
                        align-items: center;
                        flex-basis: 100%;

                        h6 {
                            margin-bottom: 0.5em;
                        }
                        .message-not-opted-in {
                            background-color: #d55151;
                            color: white;
                            border-radius: 15px;
                            padding: 0 8px;
                            margin-left: 1em;
                            margin-bottom: 0.5em
                        }
                    }
                    .message-content {
                        display: flex;
                        align-items: flex-end;
                        max-width: 75%;
    
                        .message-body-wrapper {
                            background-color: #E9EAEE;
                            border-radius: 10px;
                            padding: 8px;
                            width: fit-content;
                            margin-right: 8px;
                            position: relative;
                            .reaction-cont {
                                right: -.5em;
                            }
                            .message-body {
                                display: flex;
                                flex-direction: column;
                                flex-wrap: wrap;
                                align-items: flex-end;
                                
                                //word-break: break-all;

                                a {
                                    color: #000;
                                    text-decoration: underline;
                                }
                                .tc-media {
                                    max-width: 200px;
                                    max-height: 200px;
                                    border-radius: 10px;
                                }
                                .tc-media.tc-video {
                                    max-width: 100%;
                                    max-height: 400px;
                                }
                                .icon-as-link {
                                    display: flex;
                                    align-content: center;
                                    justify-content: center;
                                    background-color: #fff;
                                    height: 28px;
                                    width: 28px;
                                    border-radius: 6px;
                                    padding: 2px;
                                    svg {
                                        width: 24px;
                                        height: 24px;
                                        fill: #284254;
                                    }
                                }
                                .file-name-text-as-link {
                                    //width: 100%;
                                    margin-left: 8px;
                                    white-space: wrap;
                                    text-decoration: none;
                                }
                            }
                            .message-sms-media {
                                border-radius: 10px;
                                border: 1px solid #E9EAEE;
                                overflow: hidden;

                                .message-sms-media-item {
                                    cursor: pointer;
                                }
                                img {
                                    max-height: 400px;
                                    max-width: 400px;
                                    object-fit: contain;
                                }
                            }
                        }
                        .message-time {
                            color: #8089b1;
                            font-size: 14px;
                            line-height: 14px;
                            white-space: nowrap;
                        }
                    }
                }
                .reaction-button {
                    border: none;
                    border-radius: 4px;
                    width: 32px;
                    aspect-ratio: 1/1;
                    padding: 0;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #D9F1E4;
                    svg {
                        color: #399B6F;
                    }
                }
            }
        }
        .date-separator {
            display: flex;
            text-align: center;
            justify-content: center;
            align-items: center;

            span {
                width: fit-content;
            }
            .line {
                border-top: 1px solid #E9EAEE;
                margin-left: 8px;
                margin-right: 8px;
                flex: 1 1 auto;
            }
        }
        .pageinator {
            display: flex;
            justify-content: center;
            margin-top: 16px;

            .btn-paginator {
                background-color: #fff;
                border: 1px solid #E9EAEE;
                border-radius: 10px;
                padding: 8px 16px;
                margin: 0 8px;
                cursor: pointer;
            }
        }
        .system-message {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            margin: 16px 0;

            > *:first-child {
                display: flex;
                justify-content: center;
                flex-basis: 100%;

                span {
                    background-color: #E9EAEE;
                    border-radius: 10px 10px 0 0;
                    padding: 0 8px;
                }
            }
            > *:not(:first-child) {
                background-color: #878787;
                border-radius: 10px;
                color: #fff;
                opacity: .9;
                padding: 0.25em 1em;
                max-width: 50%;
            }
        }
    }
    .messages-footer {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        height: 10%;
        padding: 16px 24px;
        background-color: $gray-900;
        border-radius: 16px 16px 0 0;
        height: fit-content;
        align-self: end;
        .selected-files-for-upload {
            width: 100%;
            
            .selected-files-for-upload-list {
                display: flex;
                flex-wrap: wrap;
                
                .selected-file-for-upload {
                    position: relative;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    padding: 8px;
                    background-color: #848484;
                    box-sizing: border-box;
                    border-radius: 8px;
                    margin-right: 1rem;
                    margin-bottom: 1rem;
                    .remove-icon {
                        position: absolute;
                        top: -.5em;
                        right: -.5em;
                        cursor: pointer;
                        background-color: #284254;
                        border-radius: 50%;
                        width: 1em;
                        height: 1em;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        box-shadow: 2px 2px 6px 0px #000;
                        svg {
                            width: .5em;
                            height: .5em;
                        }
                    }
                    .file-icon{
                        background-color: #fff;
                        border-radius: 8px;
                        padding: 6px;
                        width: calc(1em + 12px);
                        height: calc(1em + 12px);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        > svg {
                            fill: #284254;
                            width: calc(1em - 4px);
                            height: calc(1em - 4px);
                        }
                    }
                    span {
                        color: #fff;
                        flex-basis: calc(100% - 2em);
                        white-space: nowrap;
                        padding-left: 8px;
                    }
                }
            }
        }
        .main-inputs {
            display: flex;
            flex-basis: 100%;
            justify-content: space-between;
            height: 100%;
            align-items: center;
            .user-input {
                display: flex;
                justify-content: space-between;
                flex-basis: 100%;
                position: relative;
                align-items: flex-end;
                .message-input {
                    display: block;
                    background-color: #fff;
                    font-size: 1em;
                    line-height: 1em;
                    border: 1px solid #41BA77;
                    border-radius: 16px;
                    outline: none;
                    padding: 8px;
                    word-break: break-word;
                    resize: none;
                    overflow-y: auto;
                    scroll-behavior: smooth;
                    width: calc(100% - 104px);
                    height: fit-content;
                    max-height: 175px;
                    min-height: 34px;
                    position: relative;
                    &::before {
                        content: "Type a message...";
                        position: absolute;
                        left: 8px;
                        top: 8px;
                        opacity: 0.5;
                        pointer-events: none;
                    }
                    &:focus::before {
                        display: none;
                    }
                    &:not([data-value=''])::before {
                        display: none;
                    }
                    &.disabled {
                        background-color: #E9EAEE;
                        pointer-events: none;
                        &::before {
                            display: block;
                        }
                    }
                }
                button {
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    aspect-ratio: 1/1;
                    height: 32px;
                    padding: 0;
                    border-radius: 34px;
                    max-width: 32px;
                    min-width: unset;
                    &.disabled {
                        cursor: not-allowed;
                        pointer-events: none;
                    }
                }
                button:hover &:not(.disabled) {
                    background-color: #3AA56D;
                }
                button:active &:not(.disabled) {
                    background-color: #2E8F5A;
                }
            }
            .extra-actions-button {
                display: flex;
                align-items: center;
                justify-content: center;
                aspect-ratio: 1/1;
                height: 32px;
                background-color: #41BA77;
                border-radius: 34px;
                &.disabled {
                    cursor: not-allowed;
                    pointer-events: unset;
                    opacity: 0.65;
                }
                .extra-actions-icon {
                    fill: #fff;
                    box-sizing: border-box;
                }
            }
            
            .file-upload-container {
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 0 0px;

                .MuiButtonBase-root.MuiButton-root {
                    height: 100%;
                    min-width: none;
                }
                .MuiButton-startIcon {
                    margin-right: 0;
                    margin-left: 0;
                    color: #fff;
                }
            }
            .emoji-picker-container {
                display: flex;
                justify-content: center;
                align-items: center;

                .emoji-picker-icon {
                    cursor: pointer;
                    color: #41BA77;

                    &:hover {
                        opacity: 0.65;
                    }
                }
                .emoji-picker {
                    position: absolute;
                    display: none;
                    bottom: 1em;
                    left: 2em;

                    &.active {
                        display: block;
                    }
                }
                .emoji-picker-background {
                    display: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;

                    &.active {
                        display: block;
                    }
                }
            }
            .tenor-gif-picker-container {
                display: flex;
                justify-content: center;
                align-items: center;

                .tenor-gif-picker-icon {
                    cursor: pointer;
                    color: #41BA77;

                    &:hover {
                        opacity: 0.65;
                    }
                }
                .tenor-gif-picker {
                    display: none;

                    &.active {
                        position: absolute;
                        display: block;
                        bottom: 1em;
                        left: 2em;
                    }
                }
                .tenor-gif-picker-background {
                    display: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;

                    &.active {
                        display: block;
                    }
                }
            }
            .text-danger {
                position: absolute;
                color: #d55151;
                top: 0;
                left: 0;
                width: 100%;
                height: 16px;
                padding: 2px;
                font-size: 12px;
            }
        }
    }
}
#message-extraactions-popper {
    .MuiPaper-elevation8 {
        padding: 6px;
        border-radius: 10px 10px 34px 34px;
        background-color: #D9D9D9;
        transform: translateY(6px) !important;
    }
    .file-upload-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px;
        background-color: #848484;
        border-radius: 6px;
        width: 32px;
        height: 32px;
        .MuiButtonBase-root.MuiButton-root {
            height: 100%;
            min-width: 0;
            padding: 2px 0;
            background-color: transparent !important;
            border: none !important;
        }
        .MuiButton-startIcon {
            margin-right: 0;
            margin-left: 0;
            color: #fff;
            transform: rotate(45deg);
            &:hover {
                color: #41BA77;
            }
        }
    }
    .emoji-picker-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px;
        background-color: #848484;
        border-radius: 6px;
        width: 32px;
        height: 32px;
        margin: 0;
        .emoji-picker-icon {
            cursor: pointer;
            color: #fff;

            &:hover {
                color: #41BA77;
            }
        }
        .emoji-picker {
            position: absolute;
            display: none;
            bottom: 1em;
            left: 2em;

            &.active {
                display: block;
            }
        }
        .emoji-picker-background {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &.active {
                display: block;
            }
        }
    }
    .tenor-gif-picker-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px;
        background-color: #848484;
        border-radius: 6px;
        width: 32px;
        height: 32px;
        margin: 6px 0;
        .tenor-gif-picker-icon {
            cursor: pointer;
            color: #fff;

            &:hover {
                color: #41BA77;
            }
        }
        .tenor-gif-picker {
            display: none;

            &.active {
                position: absolute;
                display: block;
                bottom: 1em;
                left: 2em;
            }
        }
        .tenor-gif-picker-background {
            display: none;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;

            &.active {
                display: block;
            }
        }
    }
    .close-picker-container {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 6px;
        background-color: #41BA77;
        border-radius: 60px;
        width: 32px;
        height: 32px;
        margin-top: 6px;
        .close-picker-icon {
            cursor: pointer;
            color: #fff;
        }
    }
}
.extra-actions-popper {
    z-index: 1400;
    margin-bottom: 48px !important;
}
.twilio-conversation-info {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    border-left: 1px solid #E9EAEE;
    height: 100%;
    min-width: 320px;
    overflow: auto;

    .conversation-info-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #E9EAEE;
        padding: 16px 24px;
        height: 10%;
        width: 100%;

        .conversation-title {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
    .conversation-info {
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
        padding: 12px 16px;
        height: 90%;
        width: 100%;

        .conversation-image {
            height: 80px;
            width: 100%;
            min-width: 100%;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                height: 100%;
                width: 100%;
                object-fit: contain;
            }
            .conversation-initials {
                background-color: #D3D6DE;
                border-radius: 100%;
                height: 80px;
                width: 80px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }
        .conversation-name {
            text-align: center;
            font-weight: 500;
            max-width: 100%;
        }
        > * {
            flex-basis: 100%;
        }
        .conversation-details {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            text-align: center;

            > * {
                flex-basis: 100%;
            }
        }
        .ant-menu {

            .ant-menu-submenu {

                .ant-menu-submenu-title {
                    padding-left: 24px !important;

                    &:hover {
                        color: #41BA77 !important;
                    }
                }
                li.ant-menu-submenu {
                    border-bottom: 1px solid #E9EAEE;

                    .member-item {
                        display: flex;
                        align-items: center;
                        justify-content: space-between;

                        .user-image {
                            display: flex;
                            height: 32px;
                            width: 32px;

                            img {
                                height: 100%;
                                width: 100%;
                                object-fit: cover;
                            }
                            .user-initials {
                                background-color: #D3D6DE;
                                border-radius: 100%;
                                height: 32px;
                                width: 32px;
                                display: flex;
                                align-items: center;
                                justify-content: center;
                            }
                        }
                        .user-name {

                            &:hover {
                                color: #41BA77 !important;
                            }
                        }
                    }
                }
            }
            .ant-menu-item {
                padding-left: 24px !important;

                &:hover {
                    color: #41BA77 !important;
                }
            }
            .ant-menu-item.disabled {
                color: #b5b6b9 !important;
                cursor: not-allowed;
                pointer-events: none;
            }
        }
    }
    > * {
        background-color: #fff;
    }
    .twilio-conversation-view-member-info {
        display: flex;
        flex-wrap: wrap;
        height: 100%;
        width: 100%;

        .member-info-options {
            display: flex;
            align-items: center;
            height: 10%;
            width: 100%;
            padding: 16px 24px;
            border-bottom: 1px solid #E9EAEE;

            span.icon-32 {
                em {
                    height: 100%;
                    width: 100%;
                }
            }
        }
        .info {
            display: flex;
            flex-wrap: wrap;
            align-content: flex-start;
            position: relative;
            height: 90%;
            padding: 16px 24px;
            width: 100%;

            .info-image {
                height: 80px;
                flex-basis: 100%;
                overflow: hidden;
                display: flex;
                align-items: center;
                justify-content: center;

                img {
                    height: 100%;
                    width: 100%;
                    object-fit: contain;
                }
                .conversation-initials {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: #D3D6DE;
                    border-radius: 100%;
                    height: 80px;
                    width: 80px;
                }
            }
            .info-name, .info-actions, .info-misc {
                flex-basis: 100%;
            }
            .info-actions {

                .info-action {

                    .value {
                        margin-left: 8px;
                    }
                }
            }
            // .info-misc {

            // }
            .info-developer-help.text-participant, .info-developer-help.chat-participant {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                align-items: flex-end;
                align-content: flex-end;
                position: absolute;
                bottom: 16px;
                // Styling
                background-color: #41BA77;
                border-radius: 15px 15px 0 0;
                color: #fff;
                padding: 8px 12px;
                width: calc(100% - 36px);
                margin-bottom: -16px;
                word-break: break-all;
                margin-left: -9px;

                .content-container {
                    height: 100%;
                    width: 100%;
                    overflow: auto;
                }
                .content-container > :first-child {
                    font-weight: 700;
                    text-align: center;
                    flex-basis: calc(100% + 16px);
                    border-bottom: 1px solid #E9EAEE;
                }
                .content-container > *:not(:first-child) {
                    margin-top: 8px;
                    display: flex;
                    flex-wrap: wrap;

                    > :first-child {
                        font-weight: 700;
                        margin-right: 8px;
                    }
                }
            }
        }
    }
}
.twilio-conversation-search {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: flex-start;
    align-content: flex-start;
    border-left: 1px solid #E9EAEE;
    height: 100%;
    overflow: auto;
    min-width: 320px;

    .conversation-search-header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-bottom: 1px solid #E9EAEE;
        padding: 16px 24px;
        height: 10%;
        width: 100%;

        .conversation-title {
            display: flex;
            align-items: center;
            height: 100%;
        }
    }
    .conversation-search-results {
        display: flex;
        align-content: flex-start;
        flex-wrap: wrap;
        justify-content: center;
        position: relative;
        padding: 12px 16px;
        height: 90%;
        width: 100%;

        .search-area {
            
            width: 100%;

            input {
                padding-right: 45px;
                padding-left: 45px;
            }
            .search-btn {
                position: absolute;
                top: 24px;
                left: 16px;
            }
            .close-search-btn {
                position: absolute;
                top: 34px;
                right: 8px;
            }
        }
        .search-results {
            height: 90%;
            width: 100%;
            overflow: auto;

            .search-result-item {
                display: flex;
                border-bottom: 1px solid #E9EAEE;
                padding: .75rem 0;
                cursor: pointer;
                * {
                    word-break: break-all;
                }
                .item-image {
                    padding-right: 12px;

                    .user-photo-no-image {
                        background-color: #D3D6DE;
                        border-radius: 100%;
                        height: 36px;
                        width: 36px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                    img {
                        height: 36px;
                        width: 36px;
                        object-fit: cover;
                        border-radius: 100%;
                    }
                }
                .item-details {
                    display: flex;
                    flex: 1 1 auto;
                    flex-wrap: wrap;
                    justify-content: space-between;

                    .details-header {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        flex-basis: 100%;

                        h6 {
                            font-weight: 600;
                            margin: 0;
                            line-height: 20px;
                            color: $body-color;
                            white-space: nowrap;
                            text-overflow: ellipsis;
                            overflow: hidden;
                            max-width: calc(100% - 106px);
                        }
                        .message-time {
                            color: $gray-500;
                            font-size: 14px;
                            line-height: 14px;
                            white-space: nowrap;
                        }
                    }
                    p {
                        flex-basis: 100%;
                        margin-bottom: 0;
                    }
                }
            }
        }
    }
    > * {
        background-color: #fff;
    }
}

// Modals
.modal-twilio {
    .search-area {
        .search-btn {
            left: 30px;
            top: 25px;
        }
        input {
            padding-left: 30px;
        }
    }
}

.modal-twilio.modal-twilio-new-conversation {

    .phone-number-container > *:first-child {
        width: 20%;
        margin-right: 10px;
    }
}

// Custom button icons
.btn-icon-info-plain {
    background-color: unset;
    border: unset;
    color: #41BA77;
    cursor: pointer;
}

// Custom buttons
.btn-edit-plain {
    color: #41BA77;
    background-color: unset;
    border: unset;
    text-align: right;
    padding: 0px 16px;
    cursor: pointer;

    &:hover {
        color: #3AA56D;
    }
}
.input-editable-otf-plain {
    text-align: center;
    font-weight: 500;
    border: unset;
    width: min-content;
    text-overflow: ellipsis;
    width: 100%;
}
.btn-small-plain {
    background-color: #7E8C9A;
    border: unset;
    color: #fff;
    cursor: pointer;
    padding: 0px 8px;
    font-size: 12px;
    font-weight: 500;
    text-align: center;
    border-radius: 16px;

    &:hover {
        background-color: #41BA77;
    }
    &.active {
        background-color: #41BA77;
    }
}
.add-reactions-popper {
    .MuiPopover-paper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3px 4px 1px;
        border-radius: 12px;
        button {
            cursor: pointer;
            padding: 0;
            border: 0;
            background: none;
        }
    }
}
.reaction-cont {
    position: absolute;
    bottom: -1.35rem;
    .reaction-icon {
        font-size: 1.5rem;
    }
}
@include media-breakpoint-down(md) {
    .twilio-conversations-list {
        width: 100% !important;
        .conversations-header {
            width: 100% !important;
        }
    }
    
    .twilio-conversations-messages {
        display: none !important;
    }
    .twilio-conversations-messages .messages-header {
        display: flex !important;
    }
    .twilio-conversation-info, .twilio-conversation-search {
        position: absolute;
        right: 0;
        box-shadow: -5px 0px 5px rgba(0, 0, 0, 0.06)
    }
    .twilio-conversations-messages .messages-list .message-item .message-item-details .message-content {
        max-width: 95% !important;
    }
    em-emoji-picker, .GifPickerReact {
        max-width: 315px !important;
    }
    .chat-open {
        .twilio-conversations-list {
            display: none !important;
        }
        .twilio-conversations-messages {
            display: grid !important;
        }
        .twilio-conversations-content {
            height: 100% !important;
        }
    }
    
}

.message-search-popper {
    padding: 1rem;
    background-color: #fff;
    border-radius: 12px;
    border: 1px solid #E9EAEE;
    min-width: 320px;
    max-width: 400px;
    max-height: 600px;
    overflow-y: scroll;
    z-index: 11;
    .search-result-item {
        display: flex;
        border-bottom: 1px solid #E9EAEE;
        padding: .75rem 0;
        cursor: pointer;
        * {
            word-break: break-all;
        }
        .item-image {
            padding-right: 12px;

            .user-photo-no-image {
                background-color: #D3D6DE;
                border-radius: 100%;
                height: 36px;
                width: 36px;
                display: flex;
                align-items: center;
                justify-content: center;
            }
            img {
                height: 36px;
                width: 36px;
                object-fit: cover;
                border-radius: 100%;
            }
        }
        .item-details {
            display: flex;
            flex: 1 1 auto;
            flex-wrap: wrap;
            justify-content: space-between;

            .details-header {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                flex-basis: 100%;

                h6 {
                    font-weight: 600;
                    margin: 0;
                    line-height: 20px;
                    color: $body-color;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    max-width: calc(100% - 106px);
                }
                .message-time {
                    color: $gray-500;
                    font-size: 14px;
                    line-height: 14px;
                    white-space: nowrap;
                }
            }
            p {
                flex-basis: 100%;
                margin-bottom: 0;
            }
        }
    }
}