.compliance-container {

    .compliance-header {
        position: relative;

    }

    .compliance-content {
        flex-direction: column;

        & > .MuiBox-root {
            background-color: #fff;
            flex: 0 0 auto;
            height: 50.5px;
            width: 100%;
            
            .MuiTabs-indicator {
                background-color: #41BA77;
            }
            .MuiButtonBase-root {
                color: rgba(0,0,0,.85);
                padding: 16px 24px;
            }
        }

        // .compliance-tabs {

        // }

        .simple-tabpanel {
            background-color: #fff;
            flex: 1 0 auto;
            height: calc(100% - 50.5px);
            width: 100%;

            .MuiBox-root {
                height: 100%;
            }

            .under-construction {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                align-items: center;
                align-content: center;
                height: 100%;
                width: 100%;
                overflow: hidden;

                & > * {
                    flex-basis: 100%;
                }
                & > h4 {
                    text-align: center;
                }

                // Rotate the MuiSvgIcon-root 45 degrees, back and forth
                .MuiSvgIcon-root {
                    animation: under-construction 2s linear infinite;
                }
                @keyframes under-construction {
                    0% {
                        transform: rotate(0deg);
                    }
                    50% {
                        transform: rotate(45deg);
                    }
                    100% {
                        transform: rotate(0deg);
                    }
                }
            }
            
            &.compliance-lexicon {
                height: calc(100% - 50.5px);
                width: 100%;
                overflow: auto;

                .lexicon-header {
                    display: flex;
                    position: sticky;
                    top: 0;
                    background-color: #fff;
                    z-index: 9999;
                    width: 100%;
                }
                
                .lexicon-grid {
                    display: grid;
                    // Dynamic columns
                    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
                    grid-gap: 0.5em;
                    padding: 1em;
                    width: 100%;
                    overflow: auto;

                    .lexicon-term {
                        display: flex;
                        flex-wrap: wrap;
                        justify-content: space-between;
                        align-items: center;
                        padding: 0.5em 1em;
                        border: 1px solid #E9EAEE;
                        border-radius: 5px;
                        background-color: #fff;
                        color: #000;
                        margin-bottom: 0.25em;

                        &.active {
                            background-color: #fff;
                            border-left: 5px solid;
                        }
                        &.active:hover {
                            background-color: #f5f5f5;
                            border-left: 5px solid;
                            border-color: #41BA77;
                        }
                        &:hover {
                            background-color: #f5f5f5;
                            border-color: #41BA77;
                            cursor: pointer;
                        }
                    }

                    // Headers should take up entire grid row
                    h6 {
                        grid-column: 1 / -1;
                    }
                }
                .lexicon-term-create-button.disabled {
                    cursor: not-allowed;
                }
            }
        }

        // .compliance-analytics-and-alerts {
            
        // }

        .compliance-kpis {
            background-color: #fff;
            // border-bottom: 1px solid #E9EAEE;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            height: 15%;
            min-height: 100px;

            .percent-of-compliant-texts-wrapper {
                position: relative;
                height: 100%;
                width: 25%;

                canvas {
                    position: absolute;
                    top: 0;
                    left: 0;
                }
                .percent {
                    position: absolute;
                    left: 50%;
                    transform: translate(-50%, 0);
                    font-size: 2em;
                    bottom: 0;
                }
            }
            .charts-tooltip {
                z-index: 9999;
            }
        }
        .compliance-main {
            background-color: #fff;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 100%;
            height: 85%;
            min-height: 500px;

            .compliance-alerts-wrapper {
                width: 50%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 1em 1.5em 1em 0;
                border-right: 1px solid #E9EAEE;

                & > h5 {
                    font-weight: 500;
                    width: 100%;
                    margin-bottom: unset;
                }
                .compliance-active-alert {
                    padding: 0.5em 0 1em 0;
                    border-top: 1px solid #E9EAEE;
                    width: 100%;
                }
                .compliance-alerts {
                    padding: 0.5em 0.5em 0.5em 0;
                    border-top: 1px solid #E9EAEE;
                    width: 100%;
                    height: 100%;
                    overflow: auto;
                    
                    & > ul {
                        position: relative;
                        list-style-type: none;
                        padding-left: 0;

                        .compliance-alert.active {
                            display: none;
                        }
                    }
                }
                .compliance-alert {
                    position: relative;
                    display: flex;
                    flex-wrap: wrap;
                    justify-content: space-between;
                    align-items: center;
                    padding: 0.5em 1em;
                    border: 1px solid $red;
                    border-radius: 5px;
                    background-color: $lt-danger;
                    color: $red;
                    margin-bottom: 0.25em;

                    &.active {
                        background-color: $white;
                        border-left: 5px solid;
                    }
                    &.active:hover {
                        background-color: $gray-100;
                        border-left: 5px solid;
                        border-color: $dk-danger;
                    }
                    &:hover {
                        background-color: $lt-danger-darker;
                        border-color: $dk-danger;
                        cursor: pointer;
                    }
                    .compliance-alert-header {
                        display: flex;
                        justify-content: space-between;
                        width: 100%;

                        // .compliance-alert-title {
                        
                        // }
                        // .compliance-alert-timestamp {

                        // }
                    }
                    .compliance-alert-body {
                        width: 100%;
                        padding: 0.5em 0;

                        .highlighted-lexicon-term {
                            color: $red;
                            font-weight: 500;
                        }
                    }
                }
            }
            .compliance-alert-context-wrapper {
                width: 50%;
                height: 100%;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                padding: 1em 1.5em 1em 1.5em;
                border-right: 1px solid #E9EAEE;

                & > h5 {
                    font-weight: 500;
                    width: 100%;
                    margin-bottom: unset;
                    display: flex;
                    justify-content: space-between;
                    align-items: flex-end;

                    & > .compliance-alert-context-search {
                        width: 50%;
                        margin-bottom: .25em;

                        &.has-search-value label:not(.Mui-focused) + div span, &.has-search-value label:not(.Mui-focused) {
                            width: 0;
                            padding: 0;
                            display: none;
                        }
                        input {
                            font-size: 0.75em;
                            line-height: 1em;
                            padding: 2px 0.5em 0 0.5em;
                        }
                        label:not(.Mui-focused) {
                            font-size: 0.75em;
                            line-height: 1em;
                            top: 50%;
                            transform: translate(5px, -50%);
                        }
                        span {
                            font-size: 0.75em;
                            line-height: 1em;
                            letter-spacing: 1.5px;
                        }
                        // Set span width to 0 and padding to 0 when label is not focused
                        label:not(.Mui-focused) + div span {
                            width: 0;
                            padding: 0;
                        }
                    }
                }
                .compliance-alert-context {
                    padding: 0.5em 0 1em 0;
                    border-top: 1px solid #E9EAEE;
                    border-right: unset;
                    width: 100%;
                    height: 100%;
                    overflow: auto;

                    .compliance-alert-context-body {

                        .compliance-alert-context-messages {

                            ul {
                                list-style-type: none;
                                padding-left: 0;
                            }
                            .system-message {
                                display: flex;
                                flex-wrap: wrap;
                                justify-content: center;
                                margin: 16px 0;
                    
                                > *:first-child {
                                    display: flex;
                                    justify-content: center;
                                    flex-basis: 100%;
                    
                                    span {
                                        background-color: #E9EAEE;
                                        border-radius: 10px 10px 0 0;
                                        padding: 0 8px;
                                    }
                                }
                                > *:not(:first-child) {
                                    background-color: #878787;
                                    border-radius: 10px;
                                    color: #fff;
                                    opacity: .9;
                                    padding: 0.25em 1em;
                                    max-width: 50%;
                                }
                            }
                            .date-separator {
                                display: flex;
                                text-align: center;
                                justify-content: center;
                                align-items: center;
                    
                                span {
                                    width: fit-content;
                                }
                                .line {
                                    border-top: 1px solid #E9EAEE;
                                    margin-left: 8px;
                                    margin-right: 8px;
                                    flex: 1 1 auto;
                                }
                            }
                            .compliance-alert-context-message {
                                margin: 16px 0;
                                
                                &.highlighted .compliance-alert-context-message-body-wrapper {
                                    color: $red !important;
                                    background-color: $lt-danger !important;
                                    border: 1px solid $red !important;
                                }
                                &.highlighted a {
                                    color: $red !important;
                                }
                                .highlighted-lexicon-term {
                                    color: $red;
                                    font-weight: 500;
                                }
                                &.advisor-user {
                                    display: flex;
                                    justify-content: flex-end;
                    
                                    .compliance-alert-context-message-details {
                                        display: flex;
                                        flex-direction: column;
                                        flex-basis: 100%;
                                        align-items: flex-end;
                                        padding-left: 16px;
                    
                                        h6 {
                                            flex-basis: 100%;
                                        }
                                        .compliance-alert-context-message-content {
                                            display: flex;
                                            flex-wrap: wrap;
                                            align-items: flex-end;
                                            max-width: 75%;
                    
                                            .compliance-alert-context-message-body-wrapper {
                                                background-color: #41BA77;
                                                color: #fff;
                                                border-radius: 10px;
                                                padding: 8px 16px;
                                                width: fit-content;
                                                margin-left: 8px;
                    
                                                .compliance-alert-context-message-body {
                    
                                                    a {
                                                        color: #fff;
                                                        text-decoration: underline;
                                                    }
                                                    .tc-media {
                                                        max-width: 200px;
                                                        max-height: 200px;
                                                        border-radius: 10px;
                                                    }
                                                    .tc-media.tc-video {
                                                        max-width: 400px;
                                                        max-height: 400px;
                                                    }
                                                    .file-name-text-as-link {
                                                        width: 100%;
                                                    }
                                                }
                                                .message-sms-media {
                                                    border-radius: 10px;
                                                    border: 1px solid #41BA77;
                                                    overflow: hidden;
                    
                                                    .message-sms-media-item {
                                                        cursor: pointer;
                                                    }
                                                    img {
                                                        max-height: 400px;
                                                        max-width: 400px;
                                                        object-fit: contain;
                                                    }
                                                }
                                            }
                                            .compliance-alert-context-message-timestamp {
                                                color: #8089b1;
                                                font-size: 14px;
                                                line-height: 14px;
                                                white-space: nowrap;
                                            }
                                        }
                                    }
                                }
                                // .message-item-image {
                    
                                // }
                                &:not(.advisor-user) {
                    
                                    .compliance-alert-context-message-details {
                                        display: flex;
                                        flex-wrap: wrap;
                        
                                        .compliance-alert-context-message-author {
                                            display: flex;
                                            align-items: center;
                                            flex-basis: 100%;
                    
                                            h6 {
                                                margin-bottom: 0.5em;
                                            }
                                            .message-not-opted-in {
                                                background-color: #d55151;
                                                color: white;
                                                border-radius: 15px;
                                                padding: 0 8px;
                                                margin-left: 1em;
                                                margin-bottom: 0.5em
                                            }
                                        }
                                        .compliance-alert-context-message-content {
                                            display: flex;
                                            flex-wrap: wrap;
                                            align-items: flex-end;
                                            max-width: 75%;
                        
                                            .compliance-alert-context-message-body-wrapper {
                                                background-color: #E9EAEE;
                                                border-radius: 10px;
                                                padding: 8px 16px;
                                                width: fit-content;
                                                margin-right: 8px;
                    
                                                .compliance-alert-context-message-body {
                    
                                                    a {
                                                        color: #000;
                                                        text-decoration: underline;
                                                    }
                                                    .tc-media {
                                                        max-width: 200px;
                                                        max-height: 200px;
                                                        border-radius: 10px;
                                                    }
                                                    .tc-media.tc-video {
                                                        max-width: 400px;
                                                        max-height: 400px;
                                                    }
                                                    .file-name-text-as-link {
                                                        width: 100%;
                                                    }
                                                }
                                                .message-sms-media {
                                                    border-radius: 10px;
                                                    border: 1px solid #E9EAEE;
                                                    overflow: hidden;
                    
                                                    .message-sms-media-item {
                                                        cursor: pointer;
                                                    }
                                                    img {
                                                        max-height: 400px;
                                                        max-width: 400px;
                                                        object-fit: contain;
                                                    }
                                                }
                                            }
                                            .compliance-alert-context-message-timestamp {
                                                color: #8089b1;
                                                font-size: 14px;
                                                line-height: 14px;
                                                white-space: nowrap;
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        .dropdown {
            display: flex;
            justify-content: center;
            align-items: center;
            border-radius: 5px;
            border: 1px solid $red;
            background-color: $white;
            position: absolute;
            right: 1em;
            top: 0.75em;

            &:hover {
                cursor: pointer;
                background-color: $lt-danger-darker;
            }
            // .icon-32:hover {

            // }
        }
    }
}