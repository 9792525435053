@import url("https://use.typekit.net/kli0bsg.css");
@import "../../../node_modules/bootstrap/scss/functions";

@import './variables';
@import "../../../node_modules/bootstrap/scss/mixins";
@import "../../../node_modules/bootstrap/scss/bootstrap";
@import './custom';
@import './message.scss';
@import './calendar.scss';
@import './client-user';
@import './cdc';
@import './responsive';
@import '../moneta-icon/styles.css';
@import '../moneta-sendbird/sendbird-icon.css';
@import './TwilioConversations.scss';
@import './Compliance.scss';
@import './Notifications.scss';
@import './General.scss';

@font-face {
    font-family: 'Mistral';
    src: url('../fonts/Mistral.eot');
    src: url('../fonts/Mistral.eot?#iefix') format('embedded-opentype'),
        url('../fonts/Mistral.woff2') format('woff2'),
        url('../fonts/Mistral.woff') format('woff'),
        url('../fonts/Mistral.ttf') format('truetype'),
        url('../fonts/Mistral.svg#Mistral') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Rage';
    src: url('../fonts/RageItalic.eot');
    src: url('../fonts/RageItalic.eot?#iefix') format('embedded-opentype'),
        url('../fonts/RageItalic.woff2') format('woff2'),
        url('../fonts/RageItalic.woff') format('woff'),
        url('../fonts/RageItalic.ttf') format('truetype'),
        url('../fonts/RageItalic.svg#RageItalic') format('svg');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}
@font-face {
    font-family: '1_DocuSign';
    src: url('../fonts/1_DocuSign.eot');
    src: url('../fonts/1_DocuSign.eot?#iefix') format('embedded-opentype'),
        url('../fonts/1_DocuSign.woff2') format('woff2'),
        url('../fonts/1_DocuSign.woff') format('woff'),
        url('../fonts/1_DocuSign.ttf') format('truetype'),
        url('../fonts/1_DocuSign.svg#1_DocuSign') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: '2_DocuSign';
    src: url('../fonts/2_DocuSign.eot');
    src: url('../fonts/2_DocuSign.eot?#iefix') format('embedded-opentype'),
        url('../fonts/2_DocuSign.woff2') format('woff2'),
        url('../fonts/2_DocuSign.woff') format('woff'),
        url('../fonts/2_DocuSign.ttf') format('truetype'),
        url('../fonts/2_DocuSign.svg#2_DocuSign') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: '3_DocuSign';
    src: url('../fonts/3_DocuSign.eot');
    src: url('../fonts/3_DocuSign.eot?#iefix') format('embedded-opentype'),
        url('../fonts/3_DocuSign.woff2') format('woff2'),
        url('../fonts/3_DocuSign.woff') format('woff'),
        url('../fonts/3_DocuSign.ttf') format('truetype'),
        url('../fonts/3_DocuSign.svg#3_DocuSign') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: '4_DocuSign';
    src: url('../fonts/4_DocuSign.eot');
    src: url('../fonts/4_DocuSign.eot?#iefix') format('embedded-opentype'),
        url('../fonts/4_DocuSign.woff2') format('woff2'),
        url('../fonts/4_DocuSign.woff') format('woff'),
        url('../fonts/4_DocuSign.ttf') format('truetype'),
        url('../fonts/4_DocuSign.svg#4_DocuSign') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: '5_DocuSign';
    src: url('../fonts/5_DocuSign.eot');
    src: url('../fonts/5_DocuSign.eot?#iefix') format('embedded-opentype'),
        url('../fonts/5_DocuSign.woff2') format('woff2'),
        url('../fonts/5_DocuSign.woff') format('woff'),
        url('../fonts/5_DocuSign.ttf') format('truetype'),
        url('../fonts/5_DocuSign.svg#5_DocuSign') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: '6_DocuSign';
    src: url('../fonts/6_DocuSign.eot');
    src: url('../fonts/6_DocuSign.eot?#iefix') format('embedded-opentype'),
        url('../fonts/6_DocuSign.woff2') format('woff2'),
        url('../fonts/6_DocuSign.woff') format('woff'),
        url('../fonts/6_DocuSign.ttf') format('truetype'),
        url('../fonts/6_DocuSign.svg#6_DocuSign') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
@font-face {
    font-family: '7_DocuSign';
    src: url('../fonts/7_DocuSign.eot');
    src: url('../fonts/7_DocuSign.eot?#iefix') format('embedded-opentype'),
        url('../fonts/7_DocuSign.woff2') format('woff2'),
        url('../fonts/7_DocuSign.woff') format('woff'),
        url('../fonts/7_DocuSign.ttf') format('truetype'),
        url('../fonts/7_DocuSign.svg#7_DocuSign') format('svg');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}