.max-214 {
    max-width: 214px;
}

.max-w-176 {
    max-width: 176px;
}

.cdc-open {
    em {
        transform: rotate(180deg);
    }
}

.deactivated {
    label {
        color: $gray-AAB1B7;
    }

    .form-control {
        color: $gray-AAB1B7;
        border-color: $gray-AAB1B7;

        &::-moz-placeholder {
            color: $gray-AAB1B7;
            opacity: 1;
        }

        &::placeholder {
            color: $gray-AAB1B7;
            opacity: 1;
        }
    }
}

.Calendar__day.-ltr.-selectedStart, .Calendar__day.-ltr.-selectedEnd, .Calendar__day.-ltr.-selectedBetween{
    border-radius: 50% 
}
.line-clamp-two{
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.search-btn-cdc {
    position: absolute;
    left: 13px;
    top: 12px;
    font-size: 18px;
    background-color: transparent;
    padding: 0;
    border: none;
}
.increase-padding-cdc-dropdown{
    padding: 9px 36px;
}