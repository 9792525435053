.message-wrapper {
    height: calc(100vh - 151px);
    display: flex;
}

.calendar-close {
    position: absolute;
    bottom: -2px;
    right: -2px;
}

.sendbird-avatar {
    &[role=button] {
        cursor: default;
    }

    [role=button] {
        cursor: default;
    }
}

.sendbird-admin-message {
    .sendbird-admin-message__text {
        border-radius: 24px;
        padding: 2px 12px;
        color: $ltdk-green !important;
        background-color: $lt-green;
        font-weight: 500;
        display: inline-block;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
    }
}

body {
    .sendbird-quote_message_input {
        .sendbird-quote_message_input__body {
            .sendbird-quote_message_input__body__sender-name {
                margin-bottom: 4px;
            }
        }
    }

    .sendbird-notification__text {
        .sendbird-icon-chevron-down {
            width: 20px;
            min-width: 20px;
            height: 20px;
            min-height: 20px;
        }
    }

    .sendbird-og-message-item-body {
        .sendbird-og-message-item-body__description {
            border-radius: 16px;

            .sendbird-og-message-item-body__description__url {
                line-height: 24px;
                font-size: 18px;
                margin: 0;
                color: $body-color;
            }
        }

        .sendbird-og-message-item-body__text-bubble {
            border-radius: 16px;
        }
    }


    &.sendbird-theme--light {
        .read-recipt{
            .sendbird-icon-color--read [class*='fill'] {
                fill: var(--sendbird-light-onlight-03);
            }
        }
        .sendbird-og-message-item-body {
            .sendbird-og-message-item-body__description {
                background-color: $gray-E9EAEE !important;
            }
        }

        .sendbird-og-message-item-body.incoming {
            .sendbird-og-message-item-body__text-bubble {
                background-color: $gray-E9EAEE !important;
            }
        }
    }

    .sendbird-thumbnail-message-item-body {
        min-width: 300px;
        max-width: 305px;
        height: 270px;

        .sendbird-thumbnail-message-item-body__video,
        .sendbird-thumbnail-message-item-body__image-cover,
        .sendbird-thumbnail-message-item-body__icon-wrapper,
        .sendbird-thumbnail-message-item-body__placeholder {
            height: 270px;
        }

        .sendbird-image-renderer__image,
        .sendbird-thumbnail-message-item-body__thumbnail {
            min-width: 300px !important;
            max-width: 305px !important;
            height: 270px !important;
        }
    }

    [class*=sendbird-label] {
        font-family: 'Segoe UI' !important;
    }

    .sendbird-label--button-2 {
        font-size: 16px;
        line-height: 20px;
    }

    .prof-ileft,
    .prof-iright {
        width: 50%;
        height: 56px;
        justify-content: center;

        // img{
        //     height: auto !important;
        //     max-height: 100%;
        // }
   
    }

    .prof-ileft {
        border-right: 1px solid $white;
    }

    .prof-iright {
        border-left: 1px solid $white;
    }

    .channel-user-photo {
        font-size: 20px;
        font-weight: normal;

        .prof-ileft,
        .prof-iright {
            height: inherit;

            //color: #fff;
       
        }
    }

    .sendbird-fileviewer {
        .sendbird-fileviewer__header {
            .sendbird-fileviewer__header__left {
                .sendbird-fileviewer__header__left__avatar {
                    height: 32px;
                }
            }
        }
    }

    &.sendbird-theme--light {
        .sendbird-app__wrap {
            .sendbird-app__channellist-wrap {
                border-color: $gray-E9EAEE;
            }
        }

        .sendbird-conversation {
            border-left-color: $gray-E9EAEE;
            border-top: none;
            border-right: none;
            border-bottom: none;
        }

        .sendbird-channel-list__header {
            height: auto;
        }

        .sendbird-channel-header {
            height: auto;
            width: auto;
            min-height: auto;

            .sendbird-channel-header__title {
                height: auto;
                align-items: center;
                padding: 20px 24px;
                width: auto;

                &:hover {
                    background-color: transparent;
                }

                .sendbird-channel-header__title__left {
                    width: 56px;
                    height: 56px;
                    padding: 0;
                    display: none;
                }

                .sendbird-channel-header__title__right {
                    margin-left: 0;
                    padding-top: 0;
                }
            }
        }

        .sendbird-message-item-menu__trigger {
            &:hover {
                background-color: $lt-green;
            }
        }

        .sendbird-iconbutton {
            &:hover {
                background-color: transparent;
            }
        }

        .sendbird-icon--pressed,
        .sendbird-reactions--pressed {
            .sendbird-iconbutton {
                background-color: $lt-green;
            }
        }

        .sendbird-channel-preview__content__upper__header {
            align-items: flex-start;
        }

        .sendbird-channel-preview--active {
            background-color: $lt-green;

            .sendbird-channel-preview__content__upper__header__channel-name {
                color: $body-color;
            }

            .sendbird-label--color-onbackground-1 {
                color: $body-color;
            }
        }

        .sendbird-label--color-onbackground-2 {
            color: $gray-500;
        }

        .sendbird-label--color-onbackground-3 {
            color: $gray-600;
        }

        .sendbird-channel-preview {
            &:hover {
                background-color: $lt-green;
            }
        }

        .sendbird-conversation {
            .sendbird-avatar {
                .sendbird-avatar-img {
                    background-color: $gray-300;
                }

                .sendbird-avatar-img--default {
                    background-color: $gray-300;
                }
            }
        }

        .sendbird-avatar {
            min-width: 32px;
            min-height: 32px;
            width: 32px !important;
            height: 32px !important;
            cursor: default;

            .sendbird-avatar-img--default {
                background-color: $gray-300;
            }

            .sendbird-avatar-img {
                height: 32px !important;
            }
        }

        .sendbird-iconbutton {
            .sendbird-iconbutton__inner {
                .sendbird-icon-search {
                    path {
                        fill: #979797;
                    }
                }
            }
        }

        .sendbird-message-input {
            .sendbird-message-input--textarea {
                font-family: 'Segoe UI';
                border-color: $gray-E9EAEE;
                color: $body-color;
                font-size: 18px;
                line-height: 24px;
                padding-right: 110px;
                vertical-align: middle;
            }

            .sendbird-message-input--placeholder {
                font-size: 18px;
                line-height: 24px;
            }
        }

        .sendbird-separator {
            .sendbird-label--color-onbackground-2 {
                color: $gray-600;
                font-weight: 500;
            }

            .sendbird-color--onbackground-4--background-color {
                background-color: $gray-100;
            }
        }

        .sendbird-label--color-oncontent-1 {
            color: $white;
        }

        .sendbird-message-content-reactions {
            &.mouse-hover {
                &.primary {
                    background-color: $success;
                }
            }

            &:hover {
                .primary {
                    background-color: $success;
                }
            }
        }

        .sendbird-text-message-item-body,
        .sendbird-file-message-item-body {
            &.mouse-hover,
            &:hover {
                &.outgoing {
                    background-color: $success;
                }
            }

            &.incoming {
                background-color: $gray-E9EAEE !important;
            }

            .sendbird-message-content-reactions {
                background-color: $gray-E9EAEE !important;
            }
        }

        .sendbird-icon-color--content-inverse {
            [class*='fill'] {
                fill: $body-color;
            }
        }

        .sendbird-dropdown__menu {
            box-shadow: $dropdown-box-shadow;
        }

        .sendbird-message-input-wrapper {
            .sendbird-message-input-wrapper__quote-message-input {
                border-top-color: $gray-E9EAEE;
            }
        }

        .sendbird-chat-header {
            border-bottom-color: $gray-E9EAEE;
        }

        .sendbird-label--color-onbackground-1 {
            color: $body-color;
        }

        .sendbird-quote-message {
            .sendbird-quote-message__replied-message {
                .sendbird-quote-message__replied-message__text-message {
                    background-color: $gray-E9EAEE;
                    opacity: 1;

                    .sendbird-quote-message__replied-message__text-message__word {
                        font-size: 16px;
                        line-height: 20px;
                    }
                }
            }

            .sendbird-quote-message__replied-to {
                .sendbird-quote-message__replied-to__text {
                    font-size: 14px;
                    line-height: 20px;
                }
            }
        }

        .sendbird-message-content-reactions {
            background-color: $gray-E9EAEE;

            &.mouse-hover,
            &:hover {
                background-color: $gray-E9EAEE;
            }
        }

        .sendbird-emoji-reactions {
            border-color: $gray-E9EAEE;
        }

        .sendbird-reaction-badge--selected {
            border: none !important;
            background-color: $lt-green;
            width: 43px;
        }

        .sendbird-reaction-badge--is-add {
            background-color: $gray-100;
            border: none !important;
        }

        .sendbird-reaction-button--selected {
            border: none !important;
            background-color: #EEEEEE !important;
        }

        .sendbird-reaction-button {
            border: none !important;

            &:hover {
                background-color: #EEEEEE !important;
            }
        }

        .sendbird-button--danger,
        .sendbird-button--primary {
            background-color: $success;
            border-color: $success;

            .sendbird-label--button-1 {
                color: $white;
            }

            &:hover,
            &:focus {
                background-color: #5ec48b;
                border-color: #54c185;
                box-shadow: none !important;
            }
        }

        .sendbird-button--secondary {
            border-color: $secondary;

            .sendbird-label--button-1 {
                color: $body-color;
            }

            &:hover,
            &:focus {
                background-color: $secondary;
                border-color: $secondary;

                .sendbird-label--button-1 {
                    color: $black;
                }
            }
        }
    }

    //
    .sendbird-message-content.outgoing .sendbird-message-content__middle {
        max-width: 300px;
    }

    .sendbird-place-holder {
        .sendbird-place-holder__body--align-top {
            .sendbird-place-holder__body--align-top__text {
                flex-wrap: wrap;
                padding: 24px !important;
            }
        }
    }

    .ant-menu-submenu-arrow {
        width: 24px;
        min-width: 24px;
        height: 24px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-style: normal;

        &:before {
            content: "\35" !important;
            font-family: "moneta" !important;
            background-color: transparent;
            width: auto;
            height: auto;
            transform: none !important;
            color: $gray-500 !important;
        }

        &:after {
            display: none;
        }
    }

    .ant-menu-submenu-open {
        &.ant-menu-submenu-inline {
            > .ant-menu-submenu-title {
                > .ant-menu-submenu-arrow {
                    transform: translateY(-50%);

                    &::before {
                        transform: rotate(90deg) !important;
                    }
                }
            }
        }
    }

    .sendbird-modal {
        z-index: 111;
    }

    .sendbird-dropdown__reaction-bar {
        overflow: auto !important;
    }

    .sendbird-context-menu {
        display: flex !important;
        height: 24px;
        align-items: center;
    }

    .sendbird-reaction-badge--is-add {
        vertical-align: middle;
    }

    .sendbird-emoji-reactions {
        padding: 4px;

        .sendbird-emoji-reactions__reaction-badge {
            margin-bottom: 0;
            height: 24px;
            align-items: center;

            .sendbird-tooltip-wrapper__children {
                height: 24px;
                align-items: center;
                display: flex;
            }
        }
    }

    .sendbird-label--body-1 {
        line-height: 24px;
        font-size: 18px;
    }

    .sendbird-conversation__no-messages {
        .sendbird-label--body-1 {
            line-height: 20px;
            font-size: 16px;
        }
    }

    .sendbird-channel-header {
        padding: 0;
    }

    .sendbird-channel-header {
        .sendbird-channel-header__title {
            .sendbird-channel-header__title__right__name {
                line-height: 24px;
                font-size: 18px;
                font-weight: 500;
            }

            .sendbird-channel-header__title__right__user-id {
                display: none;
            }
        }
    }

    .sendbird-channel-header__right-icon {
        .sendbird-iconbutton__inner {
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .sendbird-icon-create {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20px !important;
            min-width: 20px !important;
            height: 20px !important;
            min-height: 20px !important;
        }
    }

    .sendbird-channel-preview {
        border-left: 4px solid transparent;
        border-bottom-color: $gray-100 !important;
        height: auto;
        width: auto;

        .sendbird-channel-preview__content {
            .sendbird-channel-preview__content__upper {
                height: auto;
            }

            .sendbird-channel-preview__content__lower {
                .sendbird-channel-preview__content__lower__last-message {
                    line-height: 16px;
                    font-size: 14px;
                }
            }
        }
    }

    .sendbird-label--caption-3 {
        line-height: 20px;
        font-size: 14px;
    }

    .sendbird-icon {
        display: inline-flex !important;
        align-items: center;
        justify-content: center;
    }

    .sendbird-chat-header {
        .sendbird-chat-header__left__title {
            font-weight: 500;
            line-height: 24px;
            max-width: 230px;
            margin-right: 0;
        }
    }

    .sendbird-conversation__messages {
        .sendbird-conversation__messages-padding {
            overflow: auto;
            height: auto;
        }
    }

    .sendbird-label--caption-2 {
        font-weight: normal;
        font-size: 14px;
        line-height: 20px;
    }

    .sendbird-message-content {
        vertical-align: middle;
    }

    .sendbird-msg-hoc {
        margin-top: 14px;
    }

    .sendbird-conversation__scroll-container {
        justify-content: flex-end;
    }

    .sendbird-message-item-reaction-menu {
        + .sendbird-message-item-menu {
            margin-left: 4px;
        }
    }

    .sendbird-dropdown__menu {
        margin-bottom: 4px;
        border: $dropdown-border-width solid $dropdown-border-color;
        border-radius: $dropdown-border-radius;
        min-width: 120px;
        top: auto !important;

        .sendbird-dropdown__menu-item {
            padding-top: 4px;
            padding-bottom: 4px;

            + .sendbird-dropdown__menu-item {
                border-top: 1px solid $dropdown-border-color;
            }

            .sendbird-label--subtitle-2 {
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;
                color: $body-color;
                display: block;
            }

            &.disable {
                .sendbird-dropdown__menu-item__text {
                    opacity: 0.35;
                }
            }
        }
    }

    .sendbird-message-input-wrapper {
        .sendbird-label--caption-1 {
            font-weight: 500;
            line-height: 20px;
            font-size: 16px;
        }

        .sendbird-label--body-2 {
            line-height: 20px;
            font-size: 16px;
        }
    }

    .channel-block {
        padding: 12px 16px;
        border-bottom: 1px solid $gray-100;
        cursor: pointer;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            width: 4px;
            z-index: 1;
        }

        .dropdown.d-md-none {
            position: absolute;
            right: 4px;
            top: 4px;
            z-index: 1;
        }
    }

    .current-message {
        background-color: $lt-green;

        &:before {
            background-color: $success;
        }

        .bell-off {
            span {
                &:after {
                    background-color: $lt-green;
                }
            }
        }
    }

    .channel-list-photo {
        width: 56px;
        height: 56px;
        min-width: 56px;
        border-radius: 100%;
        overflow: hidden;
        background-color: $gray-300;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        em {
            color: $white;
            font-size: 24px;
            line-height: 23px;
        }
    }

    .channel-toolbar {
        .channel-list-photo {
            width: 32px;
            height: 32px;
            min-width: 32px;
            font-size: 10px;

            em {
                font-size: 14px;
            }
        }
    }

    .noread-digit {
        min-width: 20px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        text-align: center;
        color: $white;
        border-radius: 100%;
        font-size: 12px;
        background-color: $ltdk-green;
        margin-left: 8px;
    }

    .channel-right {
        font-family: 'Segoe UI';
        padding-left: 16px;
        width: calc(100% - 104px);

        h6 {
            font-weight: 600;
            margin: 0;
            line-height: 20px;
            color: $body-color;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }

        p {
            line-height: 20px;
            margin-bottom: 0;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
    }

    .mesg-time {
        color: $gray-500;
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
    }

    .sendbird-channel-list {
        min-width: 320px;
    }

    .sendbird-channel-list__body {
        display: flex;
        flex-direction: column;

        &::-webkit-scrollbar {
            -webkit-appearance: none;
            width: 4px;
            height: 4px;
            background: $gray-300;
            opacity: 1;
            cursor: pointer;
            z-index: 99;
            margin: 0px;
        }

        &::-webkit-scrollbar-thumb {
            border-radius: 2px;
            background-color: $gray-500;
            height: 2px;
            width: 2px;
            margin: 1px;
        }
    }

    .channel-right {
        font-family: 'Segoe UI';
        .icon-24 {
            border-radius: 4px;

            &:hover,
            &.active {
                background-color: $lt-green;
            }
        }

        .icon-32 {
            border-radius: 4px;

            &:hover,
            &.active {
                background-color: $lt-green;
            }
        }

        .dropdown-menu {
            left: auto;
            right: 0;
        }

        .dropdown-toggle {
            &::after {
                display: none;
            }
        }
    }

    // .sendbird-channel-list__header 
    // {
    //     display: none;
    // }
    .sendbird-channel-settings__banned-members-list {
        display: none;
    }

    .sendbird-channel-settings__muted-members-list {
        display: none;
    }

    .sendbird-channel-settings__freeze {
        display: none;
    }

    .sendbird-place-holder {
        .sendbird-place-holder__body--align-top {
            position: static;
            top: 0;

            .sendbird-place-holder__body--align-top__text {
                padding-left: 0;
                padding-right: 0;
            }
        }
    }

    .channel-profile-form {
        .channel-profile-form__avatar-button {
            top: 66px;
        }
    }

    // .sendbird-message-content.incoming .sendbird-message-content__right .sendbird-message-content-menu{display: flex;}
}

.sendbird-message-search-item {
    .sendbird-message-search-item__right {
        .sendbird-message-search-item__right__message-text,
        .sendbird-message-search-item__right__sender-name {
            font-size: 14px;
            line-height: 16px;
        }
    }
}

.message-wrapper {
    position: relative;
}

.msg-channel-header {
    position: absolute;
    left: 0;
    top: 0;
    width: 320px;
    z-index: 11;
    background-color: $white;
    padding: 16px;
    border-bottom: 1px solid $gray-E9EAEE;

    h5 {
        font-size: 18px;
        font-weight: 600;
        line-height: 24px;
    }
}

.create-channel-btn {
    background-color: transparent;
    border: none;
    padding: 0;
    background-image: url(../Images/create-message-icon.svg);
    background-position: center center;
    background-repeat: no-repeat;
}

.message-dropdown {
    min-width: 535px;
    max-width: 535px;
    right: 0;
    left: auto;
    margin-top: 4px;
    max-height: 352px;
}

.message-search-list {
    li {
        padding: 12px 24px;
        border-bottom: 1px solid $gray-100;
        font-size: 14px;
        line-height: 20px;
    }

    .icon-24 {
        border-radius: 8px;
        margin-right: 4px;
        background-color: #EEEEEE;
    }
}

.member-info {
    min-width: 320px;
    background-color: $white;
    border-left: 1px solid $gray-E9EAEE;
}

.member-info-header {
    padding: 20px 16px;
    border-bottom: 1px solid $gray-E9EAEE;

    .icon-x-outline {
        color: #AAB1B7;
    }
}

.member-photo-prof {
    padding: 24px;
    border-bottom: 1px solid $gray-E9EAEE;

    .icon-32 {
        border-radius: 6px;
        background-color: $lt-green;
        color: $ltdk-green;
    }
}

.member-cont-detail {
    padding: 24px;

    > .text-black {
        + .text-black {
            margin-top: 20px;
        }
    }
}

// .sendbird-message-search{
//   min-width: 320px;
// }
.sendbird-message-search {
    height: auto;
    flex: 1;
}

.close-search-btn {
    position: absolute;
    top: 50%;
    right: 1.75em;
    transform: translateY(-50%);
    border: none;
    padding: 0;
    background-color: transparent;
}

.channel-search-header {
    padding: 20px 16px 19px 24px;
    border-bottom: 1px solid $gray-E9EAEE;

    .icon-x-outline {
        color: #AAB1B7;
    }
}

.channel-search-sidebar {
    min-width: 320px;
    max-width: 320px;
    background-color: $white;
    border-left: 1px solid $gray-E9EAEE;

    .sendbird-message-search {
        min-width: auto;
    }
}

.channel-search-body {
    flex: 1;

    .message-search-area {
        min-width: auto;
    }
}

.sendbird-modal__header {
    .sendbird-label--h-1 {
        font-size: 24px;
        line-height: 32px;
        font-weight: 500;
    }
}

.sendbird-modal__content {
    .sendbird-modal__footer {
        margin-top: 24px;

        .sendbird-button {
            width: auto !important;
            height: auto !important;
            padding: $btn-padding-y $btn-padding-x;
            min-width: 96px;

            &:last-child {
                margin-left: 16px;
            }
        }

        .sendbird-button--big {
            .sendbird-label--button-1 {
                margin: 0;
                font-size: $btn-font-size;
                line-height: $btn-line-height;
                font-weight: 500;
            }
        }
    }
}

.channel-edit {
    position: absolute;
    top: 10px;
    right: 24px;
}

.member-dropmenu {
    .btn {
        font-size: 16px;
        line-height: 24px;
        padding-top: 3px;
        padding-bottom: 3px;
    }

    .ant-menu {
        border: none;
        padding: 0 !important;
        min-width: auto;
        width: 100%;
        color: $body-color;

        &:not(.ant-menu-horizontal) {
            .ant-menu-item-selected {
                background-color: $gray-100 !important;

                .ant-dropdown-trigger {
                    visibility: visible;
                }
            }

            .ant-menu-item-active {
                &.ant-menu-item-selected {
                    .ant-dropdown-trigger {
                        visibility: visible;
                    }
                }
            }
        }
    }

    .ant-menu-item,
    .ant-menu-submenu-title {
        &:active {
            background-color: $gray-100 !important;
        }

        &:hover {
            .ant-dropdown-trigger {
                visibility: visible;
            }
        }
    }

    .ant-menu-submenu-title {
        font-size: 16px;
        line-height: 24px !important;
        color: $body-color !important;
        border-bottom: 1px solid $gray-E9EAEE;
        font-weight: 500;

        .icon-24 {
            font-size: 20px;
        }

        .digit-circle {
            background-color: $gray-100;
            font-size: 14px;
            line-height: 20px;
            font-weight: 500;
            color: $gray-500;
        }

        .ant-menu-item-icon {
            + span {
                margin-left: 16px;
            }
        }
    }

    .ant-menu-inline {
        .ant-menu-item,
        .ant-menu-submenu-title {
            margin-top: 0;
            margin-bottom: 0;
        }

        .ant-menu-item,
        .ant-menu-submenu-title {
            width: 100%;
        }
    }

    .ant-menu-sub {
        padding-bottom: 8px !important;
        border-bottom: 1px solid $gray-E9EAEE !important;

        &.ant-menu-inline {
            background-color: $white;

            .ant-menu-item {
                &::after {
                    display: none;
                }
            }

            > .ant-menu-item {
                position: relative;
                margin-bottom: 0;
                font-size: 16px;
                line-height: 20px;
                color: $body-color !important;
                padding-left: 55px !important;

                &.invite-member-btn {
                    padding-left: 16px !important;
                }
            }
        }
    }

    .ant-dropdown-trigger {
        visibility: hidden;
        border-radius: 4px;

        &.ant-dropdown-open {
            background-color: $lt-green;
            color: $ltdk-green;
        }
    }
}

.ant-menu-inline {
    &.ant-menu-root {
        .ant-menu-submenu-title {
            padding-left: 16px !important;
            padding-right: 46px;
        }

        .ant-menu-item {
            > .ant-menu-title-content {
                display: flex;
                align-items: center;
                justify-content: space-between;

                .digit-circle {
                    position: absolute;
                    left: 16px;
                    top: 8px;
                    color: inherit;
                    font-size: 10px;
                    overflow: hidden;
                    img {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
            }
        }
    }
}

.digit-circle {
    background-color: $gray-300;
    border-radius: 100%;
    color: $white;
}

.leave-channel-btn {
    background-color: transparent;
    padding: 0;
    border: none;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    border-bottom: 1px solid $gray-E9EAEE;
    color: $body-color;
    padding: 11px 16px;
    width: 100%;
}

.silent-bell {
    position: relative;
    color: $gray-500;
    margin-left: 4px;
}

.max-chann {
    max-width: calc(100% - 106px);
}

.channel-toolbar {
    padding: 16px 16px 20px;
    border-bottom: 1px solid $gray-E9EAEE;
}

.channel-name {
    font-family: 'Segoe UI';
    max-width: 400px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    font-size: 18px;
}

.filtbyclient-modal {
    .search-area {
        .form-control {
            padding-left: 45px;
            padding-right: $input-padding-x;
        }

        .search-btn {
            left: 18px;
            right: 0;
        }
    }
}

@include media-breakpoint-up(md) {
    .channel-block {
        &:hover {
            .dropdown.d-md-none {
                display: block !important;
            }

            .mesg-time {
                visibility: hidden;
            }
        }
    }
}


@media (max-width: 1450px) {
    .channel-name {
        max-width: 300px;
    }
}

@media (max-width: 1350px) {
    .channel-search-sidebar {
        position: absolute;
        right: 0;
        top: 0;
        bottom: 0;
    }
}

@include media-breakpoint-down(xl) {
    .channel-name {
        max-width: 200px;
    }
}


@media (max-width: 1024px) {
    body {
        .sendbird-channel-list {
            min-width: 300px;
            width: 300px;
        }
    }

    .msg-channel-header {
        width: 300px;
    }
}

@include media-breakpoint-down(md) {
    .sendbird-channel-list__header {
        display: none;
    }

    .sendbird-conversation {
        display: none;
    }

    .message-wrapper {
        width: 100vw;
    }

    body {
        .sendbird-channel-list {
            min-width: 100%;
            width: 100vw;
            max-width: 100%;

            /*display: none;*/
        }

        .channel-block {
            .dropdown {
                &.d-md-none {
                    position: relative;
                    right: 0;
                    top: 0;
                }
            }
        }

        .sendbird-message-content {
            &.outgoing {
                .sendbird-message-content__middle {
                    max-width: 205px;
                }
            }
        }

        .sendbird-thumbnail-message-item-body {
            min-width: 200px;
            max-width: 255px;
            height: 190px;

            .sendbird-thumbnail-message-item-body__video,
            .sendbird-thumbnail-message-item-body__image-cover,
            .sendbird-thumbnail-message-item-body__icon-wrapper,
            .sendbird-thumbnail-message-item-body__placeholder {
                height: 190px;
            }

            .sendbird-image-renderer__image,
            .sendbird-thumbnail-message-item-body__thumbnail {
                min-width: 200px !important;
                max-width: 255px !important;
                height: 190px !important;
            }
        }

        .sendbird-og-message-item-body {
            min-width: 184px !important;
            max-width: 184px !important;

            .sendbird-og-message-item-body__og-thumbnail {
                .sendbird-og-message-item-body__og-thumbnail__image {
                    min-width: 184px !important;
                    max-width: 184px !important;
                    height: 170px !important;
                }
            }
        }

        .sendbird-conversation__footer {
            border-top: 1px solid $gray-E9EAEE;
            padding: 8px 0 24px;
        }

        /**/
        .sendbird-chat-header {
            .sendbird-chat-header__left__title {
                max-width: 180px;
            }

            .sendbird-chat-header__right__info {
                margin-left: 8px;
            }
        }

        .sendbird-chat-header__left {
            margin-right: 8px;
        }

        &.sendbird-theme--light {
            .sendbird-iconbutton {
                .sendbird-iconbutton__inner {
                    .sendbird-icon-search path {
                        fill: #000;
                    }
                }
            }

            .sendbird-conversation {
                border: none !important;
            }
        }
    }

    .channel-search-header {
        padding: 16px;
    }

    .chat-open {
        .sendbird-channel-list {
            display: none;
        }

        .sendbird-conversation {
            display: flex;
        }

        .heading-wrap {
            display: none !important;
        }

        .message-wrapper {
            height: calc(100vh - 74px);
        }
    }

    .channel-name {
        font-size: 16px;
        max-width: 138px;
    }

    .channel-search-sidebar {
        left: 0;
        max-width: 100%;
        border-left: none;
    }

    .mem-channel-block {
        padding: 4px 12px;
    }

    .channel-mem-photo {
        width: 24px;
        height: 24px;
        min-width: 24px;
        border-radius: 100%;
        overflow: hidden;
        background-color: $gray-300;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        em {
            font-size: 16px;
        }
    }

    .member-info {
        position: absolute;
        left: 0;
        top: 0;
        right: 0;
        bottom: 0;
        z-index: 1;
    }

    .visibility-mob-0 {
        visibility: hidden;
    }

    .max-chann {
        max-width: calc(100% - 88px);
    }

    .channel-search-sidebar,
    .sendbird-message-search,
    .sendbird-channel-list,
    .member-info {
        min-width: 100px;
    }

    .search-area {
        &.message-search-area {
            min-width: auto;
        }
    }

    .mobile-search {
        .form-control {
            height: 36px;
            border-radius: 20px;
            background-color: #eee;
        }

        .search-btn,
        .close-search-btn {
            top: 8px;
            opacity: 0.38;
        }

        .close-search-btn {
            border: 1px dashed #353761;
            right: 8px;
            padding-top: 2px;
        }
    }

    .sendbird-message-input {
        .sendbird-message-input--placeholder {
            top: 16px;
        }

        .sendbird-message-input--attach {
            bottom: 14px;
        }
    }

    .sendbird-modal__content {
        margin: 0 12px;
    }

    .sendbird-quote_message_input {
        padding-top: 8px;
    }

    body {
        &.sendbird-theme--light {
            .sendbird-message-input-wrapper {
                .sendbird-message-input-wrapper__quote-message-input {
                    border-top: none;
                    border-bottom: 1px solid $gray-E9EAEE;
                    margin-bottom: 8px;
                }
            }
        }
    }
}
.sendbird-message-content.outgoing .sendbird-message-content__middle .sendbird-message-content__middle__body-container {
    width: 100%;
}
